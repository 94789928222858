// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-1acd78bc]{font-family:\"Open Sans\"}.font-header[data-v-1acd78bc]{font-family:\"Hero\"}.q-a[data-v-1acd78bc]{padding-top:20px}.q-a[data-v-1acd78bc] .section-inner{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(1,1fr)}.q-a[data-v-1acd78bc] .section-inner .to-form{width:100%;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin-bottom:30px;margin-top:-60px}.q-a[data-v-1acd78bc] .section-inner .to-form .btn{max-width:100%;width:300px}.q-a[data-v-1acd78bc] .section-inner ul{margin-top:40px;display:grid;grid-gap:80px;gap:80px;grid-template-columns:repeat(1,1fr)}.q-a[data-v-1acd78bc] .section-inner .show-more{margin-top:60px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center}.q-a[data-v-1acd78bc] .section-inner .show-more .btn{box-shadow:none;background:#fff;font-size:16px;line-height:22px}.q-a[data-v-1acd78bc] .section-inner .show-more .btn:hover{color:#01685e}.q-a[data-v-1acd78bc] .section-inner .show-more .btn i{margin-left:10px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
