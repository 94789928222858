<template>
    <Section width="lg">
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="grid">
            <div class="container">
                <component
                    :is="linkType"
                    v-if="documents[0] && documents[0].id"
                    :document="documents[0]"
                    :usp-text="slice.primary.highlighted_link_usp_text"
                    type="highlighted"
                />
            </div>

            <div class="container">
                <component
                    :is="linkType"
                    v-if="documents[1] && documents[1].id"
                    :document="documents[1]"
                    :usp-text="slice.primary.link_1_usp_text"
                />

                <component
                    :is="linkType"
                    v-if="documents[2] && documents[2].id"
                    :document="documents[2]"
                    :usp-text="slice.primary.link_2_usp_text"
                />

                <component
                    :is="linkType"
                    v-if="documents[3] && documents[3].id"
                    :document="documents[3]"
                    :usp-text="slice.primary.link_3_usp_text"
                />
            </div>
        </div>
    </Section>
</template>

<script>
import Card from './SelectedItemsCard.vue';
import Post from './SelectedItemsPost.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Card, Post },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            posts: [],
        };
    },
    async fetch() {
        if (this.linkType !== 'post') {
            return;  
        } 
        const posts = await this.$prismic.api.getByIDs(
            this.ids,
            { lang: process.env.PRISMIC_LOCALE }
        );
        this.posts = replace(posts.results);
    },
    fetchKey() {
        return `selected-items-${this.slice.id}`;
    },
    computed: {
        linkType() {
            return this.slice.primary.highlighted_link.type;
        },
        ids() {
            return [
                this.slice.primary.highlighted_link.id,
                this.slice.primary.link_1.id,
                this.slice.primary.link_2.id,
                this.slice.primary.link_3.id
            ];
        },
        documents() {
            if (this.linkType === 'post') {
                return this.posts;
            }
            if (this.linkType === 'card') {
                return this.ids
                    .map(id => this.$store.state.cards[id])
                    .filter(card => card?.id);
            }
            return [];
        }
    },
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        .grid {
            @include grid(1,20px);

            @media screen and (min-width: 700px) {
                @include grid(2,20px);
            }

            .container {
                &:first-child {}

                &:last-child {
                    @include grid(1,20px);
                }

                &-box {
                    box-shadow: $bxs;
                    @include border-radius(25px);
                    background: $white;
                    @include border(all,1px);
                    overflow: hidden;

                    .btn:hover {
                        color: $green;
                        i { margin-left: 15px; }
                    }

                    i {
                        color: $green;
                        transition: .3s ease-in-out;
                        margin-left: 10px;
                    }
                }

                .font-header{
                    word-wrap: anywhere;
                }

                &-box.default {
                    @include grid(1,0);

                    @media screen and (min-width: 500px) {
                        @include grid(2,0);
                    }

                    .font-header {
                        @include font-size(20px,8px);
                    }
                }

                &-box.highlighted {
                    height: 100%;
                    @include grid(1,0);

                    .font-header {
                        @include font-size(20px,8px);
                        
                        @media screen and (min-width: 500px) {
                            @include font-size(32px,8px);
                        }
                    }
                }
            }
        }
    }
}
</style>
