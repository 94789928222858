<template>
    <Section width="md">
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="grid">
            <div
                v-for="(step, index) in slice.items"
                :key="index"
                class="grid-container"
            >
                <div class="step-line">
                    <div class="step-circle" />
                </div>

                <div class="grid-container__header">
                    <span class="header-step font-header">{{ index+1 }}</span>
                    <span class="header-text font-header">{{ step.header }}</span>
                </div>

                <div class="grid-container__content">
                    <div
                        class="rich-text"
                        v-html="$prismic.asHtml(step.content)"
                    />
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include flex;
        }

        .grid {
            @include grid(1, 80px);
            @include spacing(margin,8,top);

            &-container {
                @include grid(1, 20px);
                position: relative;
                padding-left: 60px;
                @include device(pad) {
                    padding-left: 60px;
                }

                &:last-child {
                    .step-line { height: 0px; }
                    .step-arrow { display: none; }
                }

                .step-line {
                    position: absolute;
                    width: 4px;
                    background: $green;
                    height: calc(100% + 80px);
                    top: 10px;

                    .step-circle {
                        @include border-radius(25px);
                        @include size(20px);
                        left: -8px;
                        top: -1px;
                        background: #2fa581;
                        position: absolute;
                    }
                }

                &__header {
                    @include flex(start);
                    flex-wrap: nowrap;

                    .header-step {
                        @include font-size(52px,0);
                        color: $green;
                        margin-right: 30px;
                    }

                    .header-text {
                        @include font-size(28px, 8px);

                        @include device(mobile) {
                            @include font-size(22px);
                        }
                    }
                }

                &__content {
                    flex: 1;

                    .content-header {
                        @include font-size(20px,8px);
                        @include spacing(margin,4,bottom);
                        display: block;
                    }
                    .rich-text > p:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>
