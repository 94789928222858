<template>
    <Section 
        :width="sectionWidth" 
        class="plain-image"
    >
        <prismic-image 
            :img="slice.primary.image"
            :w="imageWidth"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.slice.primary.image_width !== 'auto' ? this.slice.primary.image_width : 'sm';
        },
        imageWidth() {
            return {
                'sm': '700',
                'md': '900',
                'lg': '1100',
                'auto': 'auto'
            }[this.slice.primary.image_width] || 'sm';
        }
    }
};
</script>
<style lang="scss" scoped>
    .plain-image::v-deep {
        .section-inner {
            display: flex;
            justify-content: center;
        }
    }
</style>