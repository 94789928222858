// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-35c8bf1e]{font-family:\"Open Sans\"}.font-header[data-v-35c8bf1e]{font-family:\"Hero\"}.section.post-quote[data-v-35c8bf1e]{margin:30px 0;background-color:#f9fafc}.section.post-quote[data-v-35c8bf1e] .section-inner{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;width:1600px!important}.section.post-quote[data-v-35c8bf1e] .section-inner img{width:75px;height:75px}@media screen and (min-width:600px){.section.post-quote[data-v-35c8bf1e] .section-inner img{width:110px;height:110px}}@media screen and (min-width:pad){.section.post-quote[data-v-35c8bf1e] .section-inner img{width:110px;height:110px}}@media screen and (max-width:600px){.section.post-quote[data-v-35c8bf1e] .section-inner .container{width:100%}.section.post-quote[data-v-35c8bf1e] .section-inner .container:first-child{margin-bottom:20px}}@media screen and (min-width:mobile){.section.post-quote[data-v-35c8bf1e] .section-inner .container{width:100%}.section.post-quote[data-v-35c8bf1e] .section-inner .container:first-child{margin-bottom:20px}}@media screen and (min-width:600px){.section.post-quote[data-v-35c8bf1e] .section-inner .container:first-child{text-align:center;flex:1;padding-right:40px}.section.post-quote[data-v-35c8bf1e] .section-inner .container:last-child{flex:4}}@media screen and (min-width:pad){.section.post-quote[data-v-35c8bf1e] .section-inner .container:first-child{text-align:center;flex:1;padding-right:40px}.section.post-quote[data-v-35c8bf1e] .section-inner .container:last-child{flex:4}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
