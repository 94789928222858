<template>
    <div class="form-container__input">
        <label :for="uniqueId">
            {{ label }}
        </label>
        <input
            :id="uniqueId"
            :ref="refText"
            type="file"
            :name="formKey"
            @change="uploadFile"
        >
        <div
            v-if="error"
            class="form-container__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash';
export default {
    props: {
        formKey: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        refText: {
            type: String,
            required: false,
            default: ''
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            file: null
        };
    },
    computed: {
        uniqueId() {
            return uniqueId(this.formKey);
        }
    },
    watch: {
        file() {
            this.$emit('set', this.file);
        }
    },
    methods: {
        uploadFile() {
            const files = this.$refs.file.files;
            if (files.length > 0) {
                this.file = files[0];
            }
            else {
                this.file = null;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    input[type="file"] {
        width: 100%;
    }
</style>