<template>
    <div class="feedback-container">
        <expand :button="$translate('guide_feedback_button', 'Lämna feedback')">
            <div v-if="!success" class="feedback-content">
                <span class="description">
                    {{ $translate('guide_feedback_description', 'Är du nöjd med resultaten du fick uteifrån dina svar? Hjälp oss förbättra guiden genom att dela med om din åsikt!') }} 
                </span>
                <div class="feedback-thumbs" :class="opinion ? `selected selected-${opinion}` : ''">
                    <i class="fa-solid fa-thumbs-up fa-2x positive" :class="{'active': opinion === 'positive'}" @click="setOpinion('positive')" />
                    <i class="fa-solid fa-thumbs-down fa-2x negative" :class="{'active': opinion === 'negative'}" @click="setOpinion('negative')" />
                </div>
                <div v-if="opinion" class="feedback-form">
                    <div class="form-container__input">
                        <label for="feedback">{{ formLabelText }}</label>
                        <textarea v-model="feedback" rows="3" name="feedback" class="feedback-textarea" />
                    </div>
                    <button :disabled="loading" class="btn btn-md btn-green submit-button" @click="submit()">
                        {{ $translate('guide_feedback_submit_button', 'Skicka in') }}
                    </button>
                </div>
                <div v-if="error" class="error">
                    <span>{{ $translate('guide_feedback_error_message', 'Något gick fel, försök igen senare.') }}</span>
                </div>
            </div>
            <div v-else class="feedback-content">
                <div class="success-text rich-text" v-html="$prismic.asHtml($translate('guide_feedback_success_message'))" />
            </div>
        </expand>
    </div>
</template>
<script>
import Expand from '@/components/slices/list/list-item/list-item-components/Expand.vue';
export default {
    components: {
        Expand
    },
    data() {
        return {
            opinion: '',
            feedback: '',
            success: false,
            loading: false,
            error: false
        };
    },
    computed: {
        formLabelText() {
            if (this.opinion === 'positive') {
                return this.$translate('guide_feedback_positive_input_label', 'Vad tyckte du var bra? (frivilligt)');
            }
            return this.$translate('guide_feedback_negative_input_label', 'Vad tycker du vi kan förbättra? (frivilligt)');
        }
    },
    methods: {
        submit() {
            const payload = {
                opinion: this.opinion,
                feedback: this.feedback
            };
            if (! this.loading && this.opinion) {
                this.loading = true;
                this.error = false;
            
                this.$axios.post('https://formspree.io/f/moqoqlnj', payload)
                    .then(() => {
                        this.success = true;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        this.loading = false;
                        this.error = true;
                    });
            }
        },
        setOpinion(opinion) {
            this.feedback = '';
            if (opinion === this.opinion) {
                return this.opinion = '';
            }
            this.opinion = opinion;
        }
    }
}
</script>
<style lang="scss" scoped>
.feedback-container {

    .description {
        @include font-size(16px);
    }
    ::v-deep.expand-row__button {
        justify-content: flex-start;
        padding-top: 0;
        gap: 5px;
        @include font-size(16px);
        font-weight: bold;
    }
    .feedback-content {
        padding: 20px;
        background: $lightgray;
    }
    .feedback-thumbs {
        margin: 20px 0;
        display: flex;
        gap: 10px;
        i {
            padding: 16px;
            @include border();
            border-radius: 100px;
            box-shadow: $bxs;
            &:hover{box-shadow: none;}
            cursor: pointer;
            background: $white;
            transition: box-shadow 200ms ease, background 200ms ease, color 200ms ease, transform 200ms ease-out;
        }
        &.selected i {
            box-shadow: none;
            &.negative {
                transform: translateX(calc(-100% - 10px));
            }
        }
        &.selected-positive i.positive {
            background: #29A329;
            color: $white;
            z-index: 1;
            position: relative;
        }
        &.selected-negative i.negative {
            background: #E60000;
            color: $white;
            z-index: 1;
            position: relative;
        }
    }
}
    .feedback-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .feedback-textarea {
            resize: vertical;
            width: 100%;
            background: $white;
        }
        .submit-button {
            margin-top: 10px;
            margin-left: auto;
        }
    }
    .success-text ::v-deep {
        p {
            font-weight: normal;
            @include font-size(16px);
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .error {
        margin-top: 10px;
        color: darkred;
        @include font-size(14px);
        background: rgba(179, 0, 0, 0.15);
        padding: 15px;
        border: 1px solid darkred;
        border-radius: 2px;
        @include flex();
    }
</style>