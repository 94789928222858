// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-151e8094]{font-family:\"Open Sans\"}.font-header[data-v-151e8094]{font-family:\"Hero\"}.input[data-v-151e8094]{position:relative}.input .search[data-v-151e8094]{border:0;box-sizing:border-box;border-bottom:4px solid #01685e;background:#f9fafc;padding:15px;outline:none;font-size:18px;font-family:\"Open Sans\";width:100%;margin-bottom:20px}.input .search[data-v-151e8094]:focus{border-color:#01685e}.input i[data-v-151e8094]{position:absolute;right:10px;top:13px;font-size:28px;color:#333!important;cursor:pointer}.filter[data-v-151e8094]{background:#f9fafc;padding:10px;display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;grid-gap:10px;gap:10px}.filter-button[data-v-151e8094]{padding:10px 15px;background:#fff;cursor:pointer;box-shadow:none;transition:none}.filter-button.active[data-v-151e8094]{background:#01685e;color:#fff}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
