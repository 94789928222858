<template>
    <Section
        class="content-button"
        :width="width"
    >
        <nuxt-link 
            v-if="!isOutLink && !isWebLink" 
            class="btn btn-md" 
            :class="buttonStyle" 
            :to="$prismic.linkResolver(slice.primary.link)"
        > 
            {{ slice.primary.link_text }}
        </nuxt-link>
        <button-target 
            v-else-if="isOutLink && !isWebLink && document"
            size="md"
            :class="buttonStyle"
            :link="document"
            :text="slice.primary.link_text"
        />
        <a 
            v-else 
            class="btn btn-md" 
            :class="buttonStyle" 
            :href="slice.primary.link.url" 
            target="_blank"
        >
            {{ slice.primary.link_text }}
        </a>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            documentId: this.slice.primary.link.id,
            lender: null
        };
    },
    async fetch() {
        if (this.slice.primary.link.type === 'lender' && this.isOutLink) {
            this.lender = await this.$prismic.api.getByID(
                this.documentId,
                { lang: process.env.PRISMIC_LOCALE }
            );
        }
    },
    fetchKey() {
        return `content-button${this.documentId}`;
    },
    computed: {
        width() {
            if (this.slice.primary.slice_width) {
                return this.slice.primary.slice_width;
            }
            return 'md';
        },
        buttonStyle() {
            if (this.slice.primary.style) {
                return `btn-${this.slice.primary.style}`;
            }
            return '';
        },
        isWebLink() {
            return this.slice.primary.link.link_type === 'Web';
        },
        isOutLink() {
            return this.slice.primary.out_link;
        },
        document() {
            if (this.slice.primary.link.type !== 'lender' && this.isOutLink) {
                return this.$store.state.cards[this.documentId];
            }
            return this.lender; 
        }
    }
};
</script>

<style lang="scss" scoped>
    .content-button {
        padding-top: 0 !important;
    }
</style>