<template>
    <div class="grid-inner">
        <div class="container">
            <div class="container-inner">
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <prismic-image
                        :img="card.data.image"
                        w="220"
                        h="138"
                        :lazy="false"
                    />
                </nuxt-link>

                <button-target
                    :link="card"
                    background="orange"
                    size="sm"
                />
            </div>
        </div>

        <div class="container">
            <span class="font-header">{{ card.data.title }}</span>

            <data-boxes :model="model" />
        </div>
    </div>
</template>

<script>
import DataBoxes from '@/components/slices/list/list-item/list-item-components/DataBoxes.vue';
export default {
    components: { DataBoxes },
    props: {
        id: {
            type: String,
            required: true,
            default: undefined
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.id];
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
.grid-inner {
    @include grid(1,0);

    @include device(pad) {
        @include flex(start);
    }

    .container {

        &:first-child {
            @include flex;

            .container-inner {
                @include grid(1,10px);

                .btn { max-width: 220px; }
            }
        }

        &:last-child {
            @include spacing(padding,6);
            flex: 1;
            @include grid(1,10px);
            @include device(mobile) {
                text-align: center;
            }

            .font-header { @include font-size(20px); }
        }
    }
}
</style>
