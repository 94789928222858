<template>
    <Section width="lg">
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <list-filter
            :active-filter="activeFilter"
            filter-key="postCategories"
            @set="setFilter"
            @query="setSearchParam"
        />

        <ul
            v-if="list.length"
            class="card-list"
        >
            <blog
                v-for="item in list"
                :key="item.id"
                :blog="item"
            />
        </ul>

        <div
            v-else
            class="empty-list font-header"
        >
            {{ $translate('no_results', 'Inga matchingar') }}
        </div>
    </Section>
</template>

<script>
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
import ListFilter from './ListFilter.vue';
import Blog from './list-item/ListItemBlog.vue';
export default {
    components: { ListFilter, Blog },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            activeFilter: 'alla',
            searchQuery: '',
            allPosts: []
        };
    },
    async fetch() {
        this.allPosts = replace(await queryAll(this.$prismic.api, 
            this.$prismic.predicates.at('document.type', 'post'),
            { 
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.last_publication_date desc ]' 
            }
        ));
    },
    fetchKey: 'all-posts',
    computed: {
        list() {

            if (this.searchQuery.length) {
                this.activeFilter = 'alla';
                const searchQuery = this.searchQuery.toLowerCase();
                return this.allPosts.filter(item =>
                    item.data.post_title.toLowerCase().includes(searchQuery)
                );
            }

            
            if (this.activeFilter === 'alla') {
                return this.allPosts; 
            }

            return this.allPosts.filter(item => 
                this.getCategoryName(item.data.post_category.id) === this.activeFilter
            );
            
        },
    },
    methods: {
        setFilter(filter) {
            if (filter === 'alla') {
                this.activeFilter = 'alla'; 
            }
            else {
                this.activeFilter = filter.data.category; 
            }
        },
        getCategoryName(categoryId) {
            if (categoryId) {
                return this.$store.state.postCategories[categoryId].data.category; 
            }
        },
        setSearchParam(input) {
            this.searchQuery = input.toLowerCase();
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            @include grid(1,20px);

            .card-list {
                width: 100%;
                margin-top: 40px;
                @include grid(1, 60px);
            }
        }
    }
</style>
