<template>
    <div class="wrapper wrapper-post">
        <Banner :document="document" />
        <Slices :slices="document.data.body" />
        <AuthorBox :document="document" />
    </div>
</template>

<script>
import AuthorBox from '@/components/AuthorBox.vue';
import Banner from './PostBanner.vue';
import Slices from '@/components/slices/Slices.vue';
export default {
    components: { AuthorBox, Banner, Slices },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .wrapper-post::v-deep {
        .slices {
            .section {
                padding: 30px 20px;
                &:first-child { margin-top: 0; }

                &:not(.content-button) .section-inner {
                    width: 900px;
                }
            }
        }
    }
</style>
