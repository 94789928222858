<template>
    <div class="progressbar">
        <div class="progressbar-inner" :style="`width: ${progressbarWidth}%`" />
    </div>
</template>

<script>
export default {
    props: {
        elementId: {
            type: String,
            required: false,
            default: ''
        },
        bodyScroll: {
            type: Boolean,
            required: false,
            default: false
        },
        screenSizeOffset: {
            type: String,
            required: false,
            default: 'start'
        }
    },
    data() {
        return {
            progressbarWidth: 0,
        };
    },
    computed: {
        offset() {
            return {
                'start': 0,
                'middle': window.innerHeight / 2,
                'end': window.innerHeight,
            }[this.screenSizeOffset] || 'start';
        },
        element() {
            if (this.bodyScroll) {
                return document.getElementsByTagName('body')[0];
            }
            return document.getElementById(this.elementId);
        },
        elementHeight() {
            return this.element.offsetHeight;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scroll);
    },
    
    beforeMount() {
        window.addEventListener('scroll', this.scroll);
    },
    methods: {
        scroll() {
            // scrollPosition is the y-axis position relative to the element 
            let scrollPosition;
            if (this.bodyScroll) {
                scrollPosition = window.scrollY *-1;
            }
            else {
                scrollPosition = this.element.getBoundingClientRect().top - this.offset;
            }
            let width = (scrollPosition / this.elementHeight) * -100;
            if (width < 0) {
                width = 0;
            } 
            else if (width > 100) {
                width = 100;
            }
            this.progressbarWidth = width;
        },
    },
}
</script>

<style lang="scss" scoped>
    .progressbar {
        width: 100%; 
        z-index: 999;
        height: 16px; 
        background: $graybg; 
        position: fixed; 
        bottom: 0;
        left: 0;
        box-shadow: 0 2px 20px 5px rgb(0 0 0 / 16%);
        &-inner {
            background: $green; 
            height: 100%;
        }
    }
</style>