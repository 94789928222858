<template>
    <div
        class="compare-btn"
        @click="toggleCompare()"
    >  
        <span v-if="!cardIsAdded">{{ $translate('card_comparison_add_card', 'Jämför kort') }}</span>
        <span v-else>{{ $translate('card_comparison_remove_card', 'Ta bort kort') }}</span>

        <div class="compare-btn__checkbox">
            <i
                v-if="cardIsAdded"
                class="fa-regular fa-check fa-check1"
            />
        </div>
    </div>
</template>

<script>
import { includes } from 'lodash';
export default {
    props: {
        id: {
            type: String,
            required: true,
            default: String
        }
    },
    computed: {
        cardIsAdded(){
            return includes(this.$store.state.cardsToCompare, this.id);
        }
    },
    methods: {
        toggleCompare(){
            if (this.cardIsAdded) {
                this.$store.commit('REMOVE_CARD_FROM_COMPARE', this.id);
            }
            else {
                this.$store.commit('ADD_CARD_TO_COMPARE', this.id);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .compare-btn {
        margin-bottom: 20px;
        @include flex;
        font-size: 14px;
        cursor: pointer;
        @include device(pad) {
            justify-content: flex-end;
        }

        &__checkbox {
            @include size(20px);
            @include border(all,1px,$green);
            @include border-radius(3px);
            margin-left: 10px;
            @include flex;
        }
    }
</style>
