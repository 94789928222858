<template>
    <div
        class="rich-text"
        :class="align"
        v-html="$prismic.asHtml(data.content)"
    />
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            required: false,
            default: 'left'
        },
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
