// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-a712e2a4]{font-family:\"Open Sans\"}.font-header[data-v-a712e2a4]{font-family:\"Hero\"}.list-item a[data-v-a712e2a4]{display:grid;grid-gap:0;gap:0;grid-template-columns:repeat(1,1fr);text-decoration:none;color:#333}.list-item a[data-v-a712e2a4]:hover{color:#01685e}@media screen and (min-width:800px){.list-item a[data-v-a712e2a4]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}}.list-item__image[data-v-a712e2a4]{border-radius:10px}@media screen and (max-width:800px){.list-item__image[data-v-a712e2a4]{width:100%;-o-object-fit:cover;object-fit:cover;margin-bottom:-70px}}.list-item__content[data-v-a712e2a4]{flex:1;display:grid;grid-gap:15px;gap:15px;grid-template-columns:repeat(1,1fr)}@media screen and (max-width:800px){.list-item__content[data-v-a712e2a4]{display:grid;grid-gap:10px;gap:10px;grid-template-columns:repeat(1,1fr);padding-top:30px}}@media screen and (min-width:800px){.list-item__content[data-v-a712e2a4]{padding-left:30px}}.list-item__content .content-title[data-v-a712e2a4]{font-size:24px;line-height:32px;box-sizing:border-box;background:#fff;max-width:calc(100% - 40px)}@media screen and (max-width:800px){.list-item__content .content-title[data-v-a712e2a4]{border-top-right-radius:15px;padding:10px 20px 0 0}}.list-item__content .content-category[data-v-a712e2a4]{background:#fff;font-weight:700;color:#01685e;text-transform:uppercase;max-width:calc(100% - 40px)}@media screen and (max-width:800px){.list-item__content .content-category[data-v-a712e2a4]{border-top-right-radius:15px;margin-top:5px;padding:20px 20px 0 0}}.list-item__footer[data-v-a712e2a4]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;width:100%}.list-item__footer .read-time[data-v-a712e2a4]{margin-right:20px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
