// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-25ed0ac6]{font-family:\"Open Sans\"}.font-header[data-v-25ed0ac6]{font-family:\"Hero\"}.card-list__item[data-v-25ed0ac6]{display:flex;flex-direction:column;justify-content:space-between;grid-gap:1rem;gap:1rem;box-shadow:0 10px 15px rgba(169,177,200,.25);box-sizing:border-box}@media screen and (max-width:600px){.card-list__item[data-v-25ed0ac6]{padding:16px}}@media screen and (min-width:600px){.card-list__item[data-v-25ed0ac6]{padding:16px}}@media screen and (min-width:900px){.card-list__item[data-v-25ed0ac6]{padding:20px}}.card-list__item .wrapper[data-v-25ed0ac6]{display:flex;flex-direction:column;justify-content:space-between;grid-gap:1rem;gap:1rem}.card-list__item .wrapper .card-img a[data-v-25ed0ac6]{display:flex;flex-direction:column;align-items:center;grid-gap:1rem;gap:1rem}.card-list__item .wrapper .card-content[data-v-25ed0ac6]{text-align:left}.card-list__item .wrapper .card-content[data-v-25ed0ac6] p:first-child{margin-top:0!important}@media screen and (max-width:600px){.card-list__item .wrapper .list-item-card-image[data-v-25ed0ac6]{height:100px!important;width:150px!important}}@media screen and (min-width:mobile){.card-list__item .wrapper .list-item-card-image[data-v-25ed0ac6]{height:100px!important;width:150px!important}}.card-list__item .button[data-v-25ed0ac6]{width:100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
