<template>
    <Section
        :class="slice.slice_label"
        :width="slice.primary.slice_width"
    >
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="table-container mobile-scroll-shadow">
            <table
                :class="slice.primary.header_position"
                class="table-container__table"
            >
                <tr
                    v-for="(row, index) in tableData"
                    :key="index"
                >
                    <td v-for="(column, columnIndex) in row" :key="columnIndex">
                        <div 
                            class="rich-text col-text"
                            v-html="column"    
                        />
                    </td>
                </tr>
            </table>
        </div>
        <div 
            v-if="$validateText(slice.primary.footer_text)"
            class="rich-text footer-text"
            v-html="$prismic.asHtml(slice.primary.footer_text)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableData() {
            return this.slice.items
                .map(values => {
                    return Object.entries(values)
                        .filter(([key, value]) => {
                            if (key === 'column_1') {
                                return true;
                            }
                            return key.startsWith('column_') && this.$validateText(value);
                        })
                        .map(([, value]) => {
                            if (typeof value === 'object') {
                                return this.$prismic.asHtml(value)
                                    .replaceAll('<p>', '')
                                    .replaceAll('</p>', '');
                            }
                            return value;
                        });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .table-container {
            overflow: auto;
            @include border-radius(15px);
            @include border(all,1px,$purplebg);
            box-shadow: $bxs;

            table {
                width: 100%;

                tr {
                    @include border(bottom,1px,$purplebg);
                    &:last-child { border: 0; }
                    &:nth-child(even) {
                        background: $graybg;
                    }

                    td {
                        min-width: 120px;
                        padding: 25px;
                        @include font-size(14px,8px);
                        @include border(right,1px,$purplebg);
                        vertical-align: middle;
                        box-sizing: content-box;
                        @include device(mobile) {
                            min-width: 90px;
                            @include font-size(12px,8px);
                            padding: 12px;
                        }

                        &:last-child { border: 0; }
                    }
                    .col-text::v-deep * {
                        @include font-size(14px,8px);
                        @include device(mobile) {
                            @include font-size(12px,8px);
                        }
                    }
                    .col-text::v-deep ul li:after {
                        top: 7px;
                    }
                }
            }

            table.first-row {
                tr:first-child {
                    font-weight: bold;
                    td { 
                        @include font-size(16px,8px); 
                        @include device(mobile) {
                            @include font-size(12px,8px);
                        }
                    }
                }
            }
            table.first-column {
                td:first-child {
                    font-weight: bold;
                    @include font-size(16px,8px);
                    @include device(mobile) {
                        @include font-size(12px,8px);
                    }
                }
            }
        }
        .footer-text::v-deep {
            margin-top: 2rem;

            & * {
                @include font-size(14px,8px);
                @include device(mobile) {
                    @include font-size(12px,8px);
                }
            }
        }
    }
</style>
