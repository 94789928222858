<template>
    <Section class="post-quote">
        <div class="container">
            <img
                src="/quote.webp"
                alt="Kortio"
                loading="lazy"
            >
        </div>

        <div class="container">
            <span class="h3">{{ slice.primary.quote }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .section.post-quote::v-deep {
        margin: 30px 0;
        background-color: $graybg;
        .section-inner {
            @include flex;
            width: 1600px !important;

            img {
                @include size(75px);

                @include device(pad) {
                    @include size(110px);
                }
            }

            .container {
                @include device(mobile) {
                    width: 100%;
                    &:first-child { margin-bottom: 20px; }
                }
                @include device(pad) {
                    &:first-child {
                        text-align: center;
                        flex: 1;
                        padding-right: 40px;
                    }
                    &:last-child { flex: 4; }
                }
            }
        }
    }
</style>