<template>
    <div>
        <div class="section-bg">
            <div class="section">
                <div class="section-width-md">
                    <div class="question-container">
                        <div class="user-icon-container">
                            <img src="../../assets/img/user-icon-kortio.png" class="user-icon">
                        </div>
                        <div>   
                            <h1 class="question-title h3">
                                {{ 
                                    $translate('user_asks', '{name} frågar:')
                                        .replace('{name}', document.data.questioner) 
                                }}
                            </h1>
                            <div class="line-under-title" />
                            <p class="user-quetion">{{ document.data.question }}</p>
                            <div class="time-and-cat">
                                <span class="timestamp font-text">
                                    {{ timeStamp }}
                                    <i class="fa-regular fa-calendar" />
                                </span>
                                <span class="category">{{ category.data.category }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-width-md">
                <div class="question-container">
                    <prismic-image
                        class="author-icon"
                        :img="author.data.image"
                        w="50"
                        h="50"
                    />
                    <div>
                        <div>
                            <h2 class="answer-title h3">
                                {{ 
                                    $translate('author_answers', '{author} svarar:')
                                        .replace('{author}', author.data.name) 
                                }}
                            </h2>
                            <div class="line-under-title" />
                        </div>
                        <div class="author-answer rich-text" v-html="$prismic.asHtml(document.data.answer)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="section similar-questions">
            <h3>{{ $translate('similar_questions', 'Liknande frågor:') }}</h3>
            <ul>
                <li v-for="(question, index) in similarQuestions" :key="index">
                    <nuxt-link :to="$prismic.linkResolver(question)">
                        {{ $truncateString(question.data.question, 125) }}
                    </nuxt-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data() {
        return {
            similarQuestions: []
        };
    },
    async fetch () {
        this.similarQuestions = (await this.$prismic.api.query(
            [
                this.$prismic.predicates.at('document.type', 'question_answer'),
                this.$prismic.predicates.not('document.id', this.document.id),
                this.$prismic.predicates.at('my.question_answer.category', this.category.id),
            ],
            { 
                orderings: '[document.last_publication_date desc]',
                lang: process.env.PRISMIC_LOCALE,
                pageSize: 3
            }
        )).results;
    },
    fetchKey() {
        return `similar-questions-${this.document.id}`;
    },
    computed: {
        author() {
            return this.$store.state.authors[this.document.data.author.id];
        },
        category() {
            return this.$store.state.questionAnswerCategories[this.document.data.category.id];
        },
        timeStamp() {
            return this.$timestamp(this.document.last_publication_date);
        }
    },
};
</script>

<style lang="scss" scoped>
    .section-width-md {
        width: 80%; 
        margin: auto;
    }
    .time-and-cat {
        margin: 1rem 0 0 0;
    }
    .section-bg {
        background-color: #f8f8f8;
        margin-top: 60px;
    }
    .username {
        margin: 0 0 1rem 0;
        font-style: italic;
    }
    .answer-title {
        margin: 0 0 0.7rem 0;
    }
    .question-title {
        margin: 0.5rem 0 0.7rem 0;
    }
    .line-under-title {
        width: 115px;
        border-bottom: 4px solid $green;
        margin: 0 0 0 0.1rem;
    }
    .user-quetion {
        padding: 1.5rem 0 0 0;
        word-break: break-word;
    }
    .question-body {
        display: flex;
        justify-content: space-between;
        margin: 2rem 0 0 0;
    }
    .question-body p {
        padding: 0 0 0 2rem;
    }
    .question-container {
        display: flex;
        font-size: 14px;
    }
    .user-icon-container {
        align-self: baseline;
        border-bottom: 3px solid #333;
        border-radius: 4rem;
        padding: 0.8rem 0.8rem;
        margin: 0 0.9rem;
    }
    .user-icon {
        height: 30px;
        max-width: 30px;
    }
    .author-icon {
        align-self: baseline;
        height: 50px;
        width: 50px;
        margin: 0 1rem;
    }
    .category {
        color: $green;
        margin: 0 0 0 1rem;
    }
    .author-role {
        color: $green;
    }
    .author-answer::v-deep > p {
        padding: 1.5rem 0 0 0;
        margin: 0;
        word-break: break-word;
    }
    .similar-questions {
        flex-direction: column;
        align-items: start;
        max-width: 500px;
        margin: 0 auto;
    }
    .similar-questions h3 {
        margin: 0 0 1rem 0;
    }
    .similar-questions ul li {
        padding: 0.5rem 0;
    }
    .similar-questions ul li a {
        font-size: 16px;
        line-height: 24px;
        color: $green;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
    @media screen and (max-width: 768px) {
        .author-icon,
        .user-icon-container {
            margin: 0 0 0.5rem 0;
        }
        .question-container {
            width: 100%;
            flex-wrap: wrap;
        }
        .section-width-md {
            width: 100%;
        }
        .question-title {
            margin: 0 0 1rem 0;
        }
    }
    @media screen and (min-width: 1025px) {
        .section-width-md {
            width: 70%;
        }
    } 
    @media screen and (min-width: 1440px) {
        .section-width-md {
            width: 55%;
        }
    } 
</style>