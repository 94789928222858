<template>
    <input
        ref="input"
        v-model="query"
        type="text"
        name="search"
        class="search"
        :placeholder="$translate('search', 'Sök')"
    >
</template>

<script>
export default {
    data() {
        return {
            query: ''
        };
    },
    watch: {
        query() {
            this.$emit('set', this.query);
        }
    },
    mounted() {
        this.$refs.input.focus();
    }
};
</script>

<style lang="scss" scoped>
.search {
    @include border(bottom,4px, $green);
    background: $graybg;
    padding: 15px;
    outline: none;
    font-size: 18px;
    font-family: $fontText;

    &:focus {
        border-color: $green;
    }
}
</style>
