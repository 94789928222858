<template>
    <span class="timestamp font-text">
        <i class="fa-regular fa-calendar" />
        {{ $timestamp(string) }}
    </span>
</template>

<script>
export default {
    props: {
        string: {
            type: String,
            required: true,
            default: undefined
        }
    }
};
</script>
