<template>
    <component
        :is="document.type"
        :document="document"
    />
</template>

<script>
import getDocument from '@/plugins/get-document';
import Post from '@/components/pages/post/Post.vue';
import Page from '@/components/pages/Page.vue';
import question_answer from '@/components/pages/QuestionAnswer.vue';
import Author from '@/components/pages/Author.vue';

export default {
    components: {
        Post,
        Page,
        question_answer,
        Author,
    },
    layout: 'site',
    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        }
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description ?? ''
                }
            ].concat(this.document.data.is_landing_page === true ? [{ name: 'robots', content: 'noindex' }] : []),
            link: [
                {
                    rel: 'canonical',
                    href: this.$prismic.linkResolver(this.document, true)
                }
            ].concat(this.hreflangs)
        };
    },
};
</script>
