import TextInput from './form-components/TextInput.vue';
import BooleanInput from './form-components/BooleanInput.vue';
import SelectInput from './form-components/SelectInput.vue';
import SliderInput from './form-components/SliderInput.vue';
export default {
    components: {
        TextInput,
        BooleanInput,
        SelectInput,
        SliderInput
    },
    data() {
        return {
            disableButton: false,
            formSuccess: false,
            formError: false
        };
    },
    methods: {
        setInput(key, input) {
            this.item[key] = input;
        },
        submit() {
        }
    }
};
