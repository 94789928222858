<template>
    <Section
        v-if="slice.primary.content.length"
        width="md"
    >
        <p>{{ slice.primary.content }}</p>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        padding-top: 80px!important;
        padding-bottom: 40px!important;

        .section-inner {
            position: relative;

            &:after,
            &:before {
                position: absolute;
                content: "";
                @include size(120px);
            }
            &:after {
                @include border(top,2px,$green);
                top: -20px;
                left: 0;
            }
            &:before {
                @include border(bottom,2px,$green);
                bottom: -20px;
                right: 0;
            }
        }

        p {
            @include font-size(20px,10px);
            font-weight: 500;
            // &:first-letter {
            //     font-weight: bold;
            //     font-size: 32px;
            //     font-family: $fontHeader;
            // }
        }
    }
</style>
