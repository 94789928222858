<template>
    <div class="rich-text">   
        <ol>
            <li v-for="(topListItem, index) in topListItems" :key="index">
                <nuxt-link :to="$prismic.linkResolver(topListItem.card)">
                    {{ topListItem.card.data.title }}:
                </nuxt-link>
                <div class="toplist-text" v-html="topListItem.toplistText" />
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        }
    },
    computed: {
        topListItems() {
            return this.items.map(item => {
                const card = this.$store.state.cards[item.card.id];
                const model = new this.$models.CreditCard(card, this.$store);
                return {
                    card: card,
                    toplistText: this.$prismic.asHtml(item.toplist_text)
                        .replace(/{interest}/ig, model.interestString)
                        .replace(/{effectiveInterest}/ig, model.effectiveInterestString)
                        .replace(/{yearlyCost}/ig, model.yearlyCostString)
                        .replace(/{maxCredit}/ig, model.getMaxCreditString(this.$translate('unlimited_max_credit', 'Obegränsad')))
                        .replace(/{interestFreeDays}/ig, model.interestFreeDays),
                };
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .toplist-text::v-deep {
        display: inline;
        p {
            display: inline;
            margin: 0;
        }
    }
</style>