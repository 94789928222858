<template>
    <Section
        width="md"
        class="q-a"
    >
        <div class="to-form">
            <Button
                v-scroll-to="'#form'"
                :text="$translate('ask_your_question_button_text', 'Ställ din fråga')"
                background="green"
                size="lg"
            />
        </div>

        <list-filter
            filter-key="questionAnswerCategories"
            :active-filter="activeFilter"
            @set="setFilter"
            @query="setSearchParam"
        />

        <ul v-if="listItems && listItems.length">
            <list-item
                v-for="listItem in listItems"
                :key="listItem.id"
                :question-answer="listItem"
            />
        </ul>

        <div
            v-else
            class="empty-list font-header"
        >
            {{ $translate('no_results', 'Inga matchingar.') }}
        </div>
    </Section>
</template>

<script>
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
import ListFilter from '@/components/slices/list/ListFilter.vue';
import ListItem from './QuestionsAndAnswersListItem.vue';
export default {
    components: { ListFilter, ListItem },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeFilter: 'alla',
            showPostsAmount: 2,
            searchQuery: '',
            questionsAndAnswers: []
        };
    },
    async fetch() {
        this.questionsAndAnswers = (await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'question_answer'),
            { 
                orderings: '[document.last_publication_date desc]',
                lang: process.env.PRISMIC_LOCALE
            }
        ));
    },
    fetchKey: 'all-questions-and-answers',
    computed: {
        pinnedItems() {
            return this.slice.items
                .filter(item => item.pinned_item.id);
        },
        orderedList() {
            const list = this.questionsAndAnswers;
            if (list.length && this.pinnedItems.length) {
                const pinnedItemsList = this.pinnedItems.map(item => {
                    const pinnedItem = list.find(listItem => listItem.id === item.pinned_item.id);
                    //  eslint-disable-next-line
                    pinnedItem.data.pinned_item = true;
                    return pinnedItem;
                });

                const questionAnswers = list
                    .filter(listItem => !this.pinnedItems
                        .some(item => item.pinned_item.id === listItem.id));

                return [...pinnedItemsList, ...questionAnswers];
            }
            return list;
        },
        listItems() {
            if (this.searchQuery.length) {
                this.activeFilter = 'alla';
                return this.orderedList.filter(item =>
                    this.getCategoryName(item.data.category.id).includes(this.searchQuery) ||
                    item.data.question.toLowerCase().includes(this.searchQuery) ||
                    this.$prismic.asText(item.data.answer).toLowerCase().includes(this.searchQuery)
                );
            }
            if (this.activeFilter === 'alla') {
                return this.orderedList;
            }

            return this.orderedList.filter(item => this.getCategoryName(item.data.category.id) === this.activeFilter);
        }
    },
    methods: {
        setSearchParam(input) {
            this.searchQuery = input.toLowerCase();
        },
        setFilter(filter) {
            if (filter === 'alla') {
                this.activeFilter = 'alla'; 
            }
            else {
                this.activeFilter = filter.data.category; 
            }
        },
        getCategoryName(categoryId) {
            return this.$store.state.questionAnswerCategories[categoryId].data.category;
        }
    }
};
</script>

<style lang="scss" scoped>
    .q-a::v-deep {
        padding-top: 20px;

        .section-inner {
            @include grid(1,20px);

            .to-form {
                width: 100%;
                @include flex;
                margin-bottom: 30px;
                margin-top: -60px;

                .btn {
                    max-width: 100%;
                    width: 300px;
                }
            }

            ul {
                margin-top: 40px;
                @include grid(1,80px);
            }

            .show-more {
                margin-top: 60px;
                @include flex;

                .btn {
                    box-shadow: none;
                    background: $white;
                    @include font-size(16px);
                    &:hover { color: $green; }
                    i { margin-left: 10px; }
                }
            }
        }
    }
</style>
