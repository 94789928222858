<template>
    <div class="rich-text__quote">
        <div class="container">
            <img
                src="/quote.webp"
                loading="lazy"
            >

            <div class="line" />
        </div>

        <div
            class="content"
            v-html="$prismic.asHtml(data.content)"
        />

        <div class="container">
            <div class="line" />

            <img
                src="/quote.webp"
                loading="lazy"
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    }
};
</script>

<style lang="scss" scoped>
    .rich-text__quote {
        position: relative;

        .content::v-deep {
            @include spacing(padding,6);
            text-align: center;
            @include font-size(24px, 10px);
            font-style: italic;
        }

        .container {
            @include flex;
            &:first-child {
                img { margin-right: -10px; }
            }
            &:last-child {
                img { margin-left: -10px; }
            }

            img {
                @include size(50px);
                position: relative;
            }
            .line {
                flex: 1;
                height: 1px;
                background: $green;
            }
        }
    }
</style>
