// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-d10c5014]{font-family:\"Open Sans\"}.font-header[data-v-d10c5014]{font-family:\"Hero\"}section[data-v-d10c5014] .section-inner{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(1,1fr)}section[data-v-d10c5014] .section-inner .card-list{width:100%;margin-top:40px;display:grid;grid-gap:60px;gap:60px;grid-template-columns:repeat(1,1fr)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
