var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-inner"},[_c('span',{staticClass:"font-header"},[_vm._v("Steg 2")]),_vm._v(" "),_c('form',{staticClass:"form-container"},[_c('select-input',{attrs:{"label":"Anställningsstatus *","name":"employmentStatus","form-key":"employmentStatus","options":[
                {
                    name: 'Tillsvidareanställd',
                    value: 1
                },
                {
                    name: 'Student',
                    value: 2,
                },
                {
                    name: 'Pensionär',
                    value: 3
                },
                {
                    name: 'Sjukpensionär',
                    value: 4
                },
                {
                    name: 'Arbetslös',
                    value: 5
                },
                {
                    name: 'Vikarie',
                    value: 6
                },
                {
                    name: 'Egenföretagare',
                    value: 7
                }
            ]},on:{"set":_vm.setInput}}),_vm._v(" "),_c('text-input',{attrs:{"label":"Arbetsgivare *","name":"employer","form-key":"employer"},on:{"set":_vm.setInput}}),_vm._v(" "),_c('text-input',{attrs:{"label":"Månadsinkomst *","name":"monthlyIncome","form-key":"monthlyIncome","allow-only-numbers":true},on:{"set":_vm.setInput}}),_vm._v(" "),_c('select-input',{attrs:{"label":"Boendeform *","name":"formOfHousing","form-key":"formOfHousing","options":[
                {
                    name: 'Villa',
                    value: 1
                },
                {
                    name: 'Bostadsrätt',
                    value: 2
                },
                {
                    name: 'Hyresrätt',
                    value: 3
                }
            ]},on:{"set":_vm.setInput}}),_vm._v(" "),_c('Button',{class:{disabled: !_vm.enableSubmit},attrs:{"background":"green","text":"Nästa steg","size":"md"},on:{"click":function($event){return _vm.$emit('next', _vm.item)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }