<template>
    <Section
        :class="slice.slice_label"
        width="lg"
    >
        <div class="container">
            <div>
                <section-header
                    :title="slice.primary.title"
                    :teaser="slice.primary.teaser"
                />

                <ul>
                    <li
                        v-for="(stepItem, index) in slice.items"
                        :key="index"
                    >
                        <div class="step-line">
                            <div class="step-circle" />
                        </div>
                        <span class="font-header">Steg {{ index+1 }}:</span>
                        <p>{{ stepItem.form_step }}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="container">
            <step-one
                v-show="step === 1"
                @next="nextStep"
            />

            <step-two
                v-show="step === 2"
                @next="nextStep"
            />

            <step-three
                v-show="step === 3"
                @next="nextStep"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            step: 1,
            applicant: []
        };
    },
    methods: {
        nextStep(item) {
            this.applicant.push(item);
            this.step++;
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .section-inner {
            @include grid(1,40px);

            @media screen and (min-width: 700px) {
                @include grid(2,80px);
            }

            .container {
                &:first-child {
                    @include flex(start,start);

                    h1 {
                        @include font-size(26px, 10px);

                        @include device(pad) {
                            @include font-size(34px, 10px);
                        }

                        @include device(desktop) {
                            @include font-size(42px, 10px);
                        }
                    }

                    ul {
                        margin-top: 40px;
                        @include grid(1,20px);

                        li {
                            padding-left: 40px;
                            position: relative;
                            @include grid(1,5px);
                            &:last-child {
                                .step-line { height: 0px; }
                            }

                            span { color: $green; }

                            .step-line {
                                position: absolute;
                                width: 4px;
                                background: $green;
                                height: calc(100% + 30px);
                                top: 6px;

                                .step-circle {
                                    @include border-radius(25px);
                                    @include size(15px);
                                    left: -5.5px;
                                    top: -1px;
                                    background: #2fa581;
                                    position: absolute;
                                }
                            }

                            span {
                                @include font-size(18px,8px);
                            }
                        }
                    }
                }

                &:last-child {
                    @include flex(center,start);

                    .container-inner {
                        width: 100%;
                        box-shadow: $bxs;
                        @include border-radius(20px);
                        @include border;
                        background: $white;
                        @include spacing(padding,6);

                        .font-header {
                            margin-bottom: 30px;
                            display: block;
                            font-size: 24px;
                            color: $green;
                        }
                    }
                }
            }
        }

        .form {
            &-container {
                label,
                .label {
                    opacity: .7;
                    font-size: 14px;
                }
            }
        }
    }
</style>
