// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-0d73f94d]{font-family:\"Open Sans\"}.font-header[data-v-0d73f94d]{font-family:\"Hero\"}.rich-text__video .video-placeholder[data-v-0d73f94d]{background:#01685e;border-radius:25px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;cursor:pointer;color:#fff;box-shadow:0 10px 15px rgba(169,177,200,.25);font-size:72px;width:100%;height:200px}@media screen and (min-width:600px){.rich-text__video .video-placeholder[data-v-0d73f94d]{height:400px}}@media screen and (min-width:pad){.rich-text__video .video-placeholder[data-v-0d73f94d]{height:400px}}.rich-text__video .video[data-v-0d73f94d] iframe{width:100%;height:200px}@media screen and (min-width:600px){.rich-text__video .video[data-v-0d73f94d] iframe{height:400px}}@media screen and (min-width:pad){.rich-text__video .video[data-v-0d73f94d] iframe{height:400px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
