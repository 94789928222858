// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-7ac4f641]{font-family:\"Open Sans\"}.font-header[data-v-7ac4f641]{font-family:\"Hero\"}.latest-posts[data-v-7ac4f641]{margin-top:48px;display:flex;flex-direction:column}.latest-posts .latest-posts-title[data-v-7ac4f641]{margin-bottom:20px}.latest-posts .posts-wrapper[data-v-7ac4f641]{text-decoration:none;border-bottom:1px solid #e6e6e6;margin-bottom:1.5rem;padding-bottom:1.5rem}.latest-posts .posts-wrapper:hover .posts-title[data-v-7ac4f641]{text-decoration:none!important}.latest-posts .posts-wrapper .posts-title[data-v-7ac4f641]{color:#01685e;font-size:18px;font-weight:700;line-height:1.75rem;text-decoration:underline}.latest-posts .posts-wrapper .posts-preamble[data-v-7ac4f641]{margin:1rem 0 .5rem;color:#000;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;-webkit-line-clamp:2}@media screen and (max-width:600px){.latest-posts .posts-wrapper .posts-preamble[data-v-7ac4f641]{-webkit-line-clamp:4}}@media screen and (min-width:mobile){.latest-posts .posts-wrapper .posts-preamble[data-v-7ac4f641]{-webkit-line-clamp:4}}.latest-posts .posts-wrapper .posts-date[data-v-7ac4f641]{display:inline-block;color:#333;opacity:.5;margin-top:.5rem}.latest-posts .load-posts[data-v-7ac4f641]{display:flex;justify-content:center;grid-column-gap:1rem;-moz-column-gap:1rem;column-gap:1rem}.latest-posts .load-posts button[data-v-7ac4f641]{margin-top:1.5rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
