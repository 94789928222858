<template>
    <button
        role="button" 
        class="btn" 
        :class="{'active': active === valueKey }" 
        @click="setActive()"
    >
        <span>{{ title }}</span>
    </button>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        valueKey: {
            type: String,
            required: true
        },
        active: {
            type: String,
            required: true
        }
    },
    methods: {
        setActive() {
            this.$emit('setActive', this.valueKey); 
        }
    }
};
</script>

<style lang="scss" scoped>
button {
    padding: 20px;
    box-shadow: unset;
    font-weight: unset;
    background: #EFEFEF;
    &:hover{
        background: $green;
        color: $white;
    }
    span {
        @include font-size(16px);
    }
    &.active {
        background: $green;
        color: $white;
        cursor: unset;
    }
}
</style>