// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-5fdc1ff4]{font-family:\"Open Sans\"}.font-header[data-v-5fdc1ff4]{font-family:\"Hero\"}.progressbar[data-v-5fdc1ff4]{width:100%;z-index:999;height:16px;background:#f9fafc;position:fixed;bottom:0;left:0;box-shadow:0 2px 20px 5px rgba(0,0,0,.16)}.progressbar-inner[data-v-5fdc1ff4]{background:#01685e;height:100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
