import { render, staticRenderFns } from "./TextBoxes.vue?vue&type=template&id=2a78ada8&scoped=true&"
import script from "./TextBoxes.vue?vue&type=script&lang=js&"
export * from "./TextBoxes.vue?vue&type=script&lang=js&"
import style0 from "./TextBoxes.vue?vue&type=style&index=0&id=2a78ada8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a78ada8",
  null
  
)

export default component.exports