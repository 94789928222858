<template>
    <li>
        <nuxt-link :to="$prismic.linkResolver(questionAnswer)">
            <div class="grid">
                <div class="grid-container grid-container__head">
                    <span class="font-header">
                        {{ getCategory(questionAnswer.data.category.id) }}
                        <i 
                            v-if="questionAnswer.data.pinned_item"
                            class="fa-solid fa-thumbtack pin-icon"
                        />
                    </span>
                </div>

                <div class="grid-container grid-container__body">
                    <span class="font-header">
                        {{ questionAnswer.data.question }}
                    </span>
                    <p class="author-answer">
                        {{ $prismic.asText(questionAnswer.data.answer) }}
                    </p>
                    <span>
                        <i class="fa-solid fa-user" />
                        {{ getAuthor(questionAnswer.data.author.id).name }}
                    </span>
                    <span>
                        <i class="fa-regular fa-calendar" />
                        {{ $timestamp(questionAnswer.last_publication_date) }}
                    </span>
                </div>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        questionAnswer: {
            type: Object,
            required: true,
        }
    },
    computed: {
    },
    methods: {
        getCategory(id) {
            return this.$store.state.questionAnswerCategories[id].data.category;
        },
        getAuthor(id) {
            return this.$store.state.authors[id].data;
        }
    },
};
</script>

<style lang="scss" scoped>
li {
    a {
        @include flex(start);
        &:hover { color: $green; }
    }

    .grid {
        flex: 1;
        @include grid(1,10px);
        @include device(pad) {
            gap: 20px;
        }

        &-container {

            &__head {
                text-transform: uppercase;

                .font-header {
                    color: $green;
                    font-size: 14px;
                    @include device(pad) {
                        font-size: 16px;
                    }
                    .pin-icon {
                        margin-left: 0.5rem;
                        transform: rotate(45deg);
                        color: $orange;
                    }
                }
            }

            &__body {
                @include grid(1,20px);
                .font-header { 
                    @include font-size(18px, 10px); 
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 5;
                    @include device(pad) {
                        @include font-size(24px, 10px); 
                        -webkit-line-clamp: 3;
                    }
                }
                i { margin-right: 5px; }

                .author-answer {
                    word-break: break-word;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 6;
                    @include device(pad) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
    }

    .container {
        flex: 1;
        max-width: 300px;
        margin-left: 80px;
        @include flex(start);

        .container-inner {
            @include grid(1,10px);
            flex: 1;
            padding-left: 20px;

            span:first-child { font-weight: bold; }
        }

        img {
            @include size(60px);
            @include border-radius(100px);
        }
    }
}
</style>
