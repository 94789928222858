<template>
    <Section
        v-if="enabled"
        width="md"
    >
        <div class="container">
            <prismic-image
                :img="author.data.image"
                w="70" 
                h="70" 
            />

            <div class="container-header">
                <nuxt-link :to="$prismic.linkResolver(author)" class="font-header">
                    {{ author.data.name }}
                </nuxt-link>
                <a :href="`mailto:${author.data.email}`" class="font-text">
                    <i class="fa-solid fa-envelope" />
                    {{ author.data.email }}
                </a>
            </div>

            <div class="container-inner">
                <p>{{ author.data.bio }}</p>
            </div>

            <div class="author-publication-date">
                <span :class="{'divider': showLastUpdated}">
                    {{ $translate('author_box_published', 'Publicerad: {timestamp}')
                        .replace('{timestamp}', $timestamp(document.first_publication_date)) }}
                </span>
                <span v-if="showLastUpdated">
                    {{ $translate('author_box_last_updated', 'Senast uppdaterad: {timestamp}')
                        .replace('{timestamp}', $timestamp(document.last_publication_date)) }}
                </span>
            </div>

            <div v-if="editor" class="author-publication-container">
                <div class="author-publication-body">
                    <PrismicImage
                        class="author-publication-image"
                        :img="editor.data.image"
                        h="35"
                        w="35"
                    />
                    <nuxt-link :to="$prismic.linkResolver(editor)" class="author-publication-name">
                        <p 
                            v-html="
                                $translate('author_box_reviewed_by_editor', 'granskad av {editor}')
                                    .replace('{editor}', `<span>${editor.data.name}</span>`)"
                        />
                    </nuxt-link>
                </div>
            </div>
        </div>

        <div
            v-if="hasLinks"
            class="container container-links"
        >   
            <span class="font-header">{{ $translate('author_box_sources', 'Källor') }}</span>
            <ul>
                <li
                    v-for="(link, index) in links"
                    :key="index"
                    v-html="isLink(link.text)"
                />
            </ul>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        enabled() {
            return this.document.data.post_author.id;
        },
        author() {
            return this.$store.state.authors[this.document.data.post_author.id];
        },
        editor() {
            return this.$store.state.authors[this.document.data.editor?.id];
        },
        hasLinks() {
            return this.document.data.links.length && this.document.data.links[0].text;
        },
        links() {
            return this.document.data.links;
        },
        showLastUpdated() {
            return this.$timestamp(this.document.first_publication_date) !== 
                this.$timestamp(this.document.last_publication_date);
        }
    },
    methods: {
        isLink(string) {
            if (string && string.includes('http')) {
                return `<a href="${string}" target="_blank">${string.substring(0, 50)}</a>`; 
            }
            return string;
        },
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        padding-top: 80px;

        .section-inner {
            @include border(top,2px,$green);
            @include grid(1,0);

            @media screen and (min-width: 800px) {
                @include flex(start,stretch);
            }

            .container {
                flex: 1;
                @include spacing(padding,4);

                .font-header {
                    @include font-size(22px);
                    color: $green;
                    &:hover:not(span) {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    @include flex(start);

                    img {
                        @include border-radius(100px);
                        object-fit: contain;
                        margin-right: 10px;
                    }

                    .container-header {
                        @include grid(1,5px);

                        .font-text {
                            &:hover {
                                color: $green;
                                // text-decoration: underline;
                            }
                        }

                        i {
                            color: $green;
                            margin-right: 5px;
                        }
                    }

                    .container-inner {
                        border-top: .0625rem solid rgba(33, 50, 91, .1);
                        @include spacing(padding,4,top);
                        @include spacing(margin,4,top);
                        width: 100%;

                        p { @include font-size(14px, 8px); }
                    }
                }

                &-links {
                    .font-header {
                        margin-bottom: 20px;
                        margin-top: 10px;
                        display: block;
                    }

                    ul {
                        li {
                            @include font-size(14px,15px);

                            a {
                                color: $green;
                                &:hover { text-decoration: underline; }
                            }
                        }
                    }
                }
                .author-publication-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 0.5rem;
                    margin-top: 20px;
                    border-top: .0625rem solid rgba(33, 50, 91, .1);
                    
                    .author-publication-body {
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        margin-top: 15px;

                        .author-publication-image {
                            margin: 0;
                        }
                        
                        .author-publication-name {
                            p {
                                @include font-size(14px);
                                margin: 0;
                            }
                            p::first-letter {
                                text-transform: capitalize;
                            }
                            p span {
                                font-weight: bold;
                                &:hover {
                                    color: $green;
                                    text-decoration: underline;
                                }
                            }
                            p:hover {
                                span {
                                    color: $green;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                 .author-publication-date {
                        @include font-size(14px);
                        opacity: .6;
                        font-weight: lighter;
                        margin-top: 20px;
                        display: flex;
                        column-gap: 1rem;
                        row-gap: 0.5rem;
                        flex-wrap: wrap;

                        span.divider {
                            position: relative;
                            &::after {
                                content: '';
                                height: 75%;
                                width: 0;
                                position: absolute;
                                right: -8px;
                                top: 3px; 
                                background-color: rgba(51, 51, 51, 0.3);
                            }
                            @include device(pad) {
                                &::after {
                                    width: 1px;
                                }
                            }
                        }
                    }
            }
        }
    }
</style>