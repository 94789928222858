// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-27ac2d62]{font-family:\"Open Sans\"}.font-header[data-v-27ac2d62]{font-family:\"Hero\"}.compare-btn[data-v-27ac2d62]{margin-bottom:20px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;font-size:14px;cursor:pointer}@media screen and (min-width:600px){.compare-btn[data-v-27ac2d62]{justify-content:flex-end}}@media screen and (min-width:pad){.compare-btn[data-v-27ac2d62]{justify-content:flex-end}}.compare-btn__checkbox[data-v-27ac2d62]{width:20px;height:20px;box-sizing:border-box;border:1px solid #01685e;border-radius:3px;margin-left:10px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
