<template>
    <div>
        <div class="author-banner">
            <prismic-image
                class="author-image"
                :img="author.data.image"
                h="150"
                w="150"
            />
            <h1>{{ author.data.name }}</h1>
            <a 
                v-if="author.data.email"
                :href="`mailto:${author.data.email}`" 
                class="author-email-wrapper"
            >
                <i class="fas fa-envelope" />
                <span class="author-email">{{ author.data.email }}</span>
            </a>
        </div>
        <div class="author-preamble">
            <div class="divider-preamble-left" />
            <Section 
                v-if="$validateText(author.data.page_preamble)" 
                width="sm" 
                class="page-preamble-section"
            >
                <div class="page-preamble rich-text" v-html="$prismic.asHtml(author.data.page_preamble)" />
            </Section>
            <div class="divider-preamble-right" />
        </div>
        <Section width="sm">
            <div 
                v-if="$validateText(author.data.page_content)" 
                class="rich-text" 
                v-html="$prismic.asHtml(author.data.page_content)" 
            />

            <latest-articles
                v-show="authorPosts.length"
                :title="$translate('latest_posts', 'Senaste artiklarna')"
                :author-articles="authorPosts"
            />

            <latest-articles 
                v-show="authorReviews.length"
                :title="$translate('posts_reviewed', 'Granskade artiklar')"
                :author-articles="authorReviews"
            />
        </Section>
    </div>
</template>
<script>
import LatestArticles from '@/components/LatestArticles.vue';
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';

export default {
    components: { LatestArticles },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            authorPosts: [],
            authorReviews: [],

        };
    },
    async fetch() {
        this.authorPosts = replace(await queryAll(this.$prismic.api, 
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.post_author', this.author.id),
            ],
            { 
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]' 
            }
        ));
        this.authorReviews = replace(await queryAll(this.$prismic.api, 
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.editor', this.author.id),
            ],
            { 
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.first_publication_date desc ]' 
            }
        ));
    },
    fetchKey() {
        return `author-${this.document.id}-posts`;
    },
    computed: {
        author() {
            return this.document;
        },
    },
};
</script>
<style lang="scss" scoped>
    .author-banner {
        background-color: rgba(230, 230, 230, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        padding-top: 60px;
        @include device(mobile) {
            margin-top: 40px;
            padding-top: 40px;
        }
        .author-image {
            border-radius: 50%;
            margin-bottom: 0.5rem;
        }
        .author-email-wrapper {
            display: flex;
            align-items: center;
            margin-top: 0.75rem;
            color: #677788;
            @include font-size(18px);
            @include device(mobile) {
                margin-top: 0.5rem;
                @include font-size(16px);
            }
            .author-email {
                font-family: $fontHeader;   
            }
            &:hover { color: $green; }
            i {
                padding-right: 0.5rem;
                color: $green;
            }
        }
    }
    .author-preamble {
        position: relative;
        padding: 2rem 0;
        background-color: rgba(230, 230, 230, 0.25);
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        @include device(mobile) {
            padding: 1.5rem 0;
        }
        .page-preamble-section {
            padding-top: 0;
            padding-bottom: 0;
            .page-preamble::v-deep p:first-child {
                margin-top: 0 !important;
            }
        }
        .divider-preamble-left {
            position: absolute;
            content: "";
            background-color: rgba(1, 104, 94, 1);
            height: 2px;
            width: 35%; 
            top: -10px;
            left: 0;
            @include device(mobile) {
                width: 12.5%;
            }
        }
        .divider-preamble-right {
            position: absolute;
            content: "";
            background-color: rgba(1, 104, 94, 1);
            height: 2px;
            width: 35%; 
            top: -10px;
            right: 0;
            @include device(mobile) {
                width: 12.5%;
            }
        }
    }
    Section {
        .section-inner {
            justify-content: flex-start;
        }
    }
</style>