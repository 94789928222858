// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-03d8165e]{font-family:\"Open Sans\"}.font-header[data-v-03d8165e]{font-family:\"Hero\"}.pros-container[data-v-03d8165e]{border-radius:15px;border:1px solid #f8ebfa;box-shadow:0 10px 15px rgba(169,177,200,.25);box-sizing:border-box}@media screen and (max-width:600px){.pros-container[data-v-03d8165e]{padding:20px}}@media screen and (min-width:600px){.pros-container[data-v-03d8165e]{padding:20px}}@media screen and (min-width:900px){.pros-container[data-v-03d8165e]{padding:25px}}.pros-container ul[data-v-03d8165e]{display:flex;flex-direction:column;grid-gap:10px;gap:10px}.pros-container ul li[data-v-03d8165e]{display:flex;align-items:baseline;font-size:16px;line-height:24px;grid-gap:10px;gap:10px}.pros-container ul li i[data-v-03d8165e]{color:#29a329}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
