// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-6163edec]{font-family:\"Open Sans\"}.font-header[data-v-6163edec]{font-family:\"Hero\"}.cta-box[data-v-6163edec]{border:0;border:1px solid rgba(241,214,245,.7);box-shadow:0 10px 15px rgba(169,177,200,.25);border-radius:25px;box-sizing:border-box}@media screen and (max-width:600px){.cta-box[data-v-6163edec]{padding:32px}}@media screen and (min-width:600px){.cta-box[data-v-6163edec]{padding:32px}}@media screen and (min-width:900px){.cta-box[data-v-6163edec]{padding:40px}}.cta-box h2[data-v-6163edec]:first-child,.cta-box h3[data-v-6163edec]:first-child,.cta-box h4[data-v-6163edec]:first-child,.cta-box h5:first-child p[data-v-6163edec]:first-child{margin-top:0}.cta-box.white[data-v-6163edec]{background:#fff}.cta-box.gray[data-v-6163edec]{background:#f9fafc}.cta-box.green[data-v-6163edec]{background:#e6fffc}.cta-box.purple[data-v-6163edec]{background:#f8ebfa}.cta-box.gray-blue[data-v-6163edec]{background:#36495a;color:#fff}.cta-box.gray-blue[data-v-6163edec]  ol li:after{color:#fff}.cta-box.gray-blue[data-v-6163edec]  ul li:after{background:#fff}.cta-box.gray-blue[data-v-6163edec]  a{color:#fff;text-decoration:underline}.cta-box.gray-blue[data-v-6163edec]  a:hover{text-decoration:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
