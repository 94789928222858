<template>
    <div v-if="settings.trust_us_card_list_enable" class="trust-us">
        <expand 
            class="trust-us-expander" 
            :button="settings.trust_us_button_text"    
            :left-icon="'fa-solid fa-shield-check fa-xl'"
        >
            <div 
                v-if="$validateText(settings.trust_us_expander_text)" 
                class="rich-text trust-us-rich-text"
                v-html="$prismic.asHtml(settings.trust_us_expander_text)" 
            />
        </expand>
    </div>
</template>

<script>
import Expand from '@/components/slices/list/list-item/list-item-components/Expand.vue';
export default {
    components: { Expand },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    }
};
</script>

<style lang="scss" scoped>
   .trust-us::v-deep {
       margin-top: 1rem;
        
        .expand-row__content.expand, 
        .expand-row__button.expand {
            background-color: $graybg;
        }
        
        .expand-row__content {
            padding: 0 40px;
            margin-bottom: 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            @include device(mobile) {
                padding: 0 32px;
                margin-bottom: 16px;
            }
        }

        .trust-us-expander .expand-row__button {
            justify-content: flex-start;
            flex-wrap: nowrap;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 20px 40px;
            @include device(mobile) {
                padding: 16px 32px;
            }
            i.left-icon {
                color: #00c9a7;
                margin-right: 0.5rem;
                transition: none;
                transform: rotate(0);
            }
            i.right-icon {
                margin-left: 1rem;
            }
        }

        .trust-us-rich-text p:first-child {
            margin: 0;
        }
        .trust-us-rich-text p:last-child {
            margin-bottom: 20px;
            @include device(mobile) {
                margin-bottom: 16px;
            }
        }
   }
</style>
