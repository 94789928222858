// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-75b4a8a6]{font-family:\"Open Sans\"}.font-header[data-v-75b4a8a6]{font-family:\"Hero\"}.cards-container[data-v-75b4a8a6]{margin-top:4rem;display:flex;flex-direction:column;grid-gap:4rem;gap:4rem}.section-header[data-v-75b4a8a6]{box-sizing:border-box}@media screen and (max-width:600px){.section-header[data-v-75b4a8a6]{margin-bottom:16px}}@media screen and (min-width:600px){.section-header[data-v-75b4a8a6]{margin-bottom:16px;margin-bottom:20px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
