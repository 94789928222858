// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-a9537eda]{font-family:\"Open Sans\"}.font-header[data-v-a9537eda]{font-family:\"Hero\"}button[data-v-a9537eda]{padding:20px;box-shadow:unset;font-weight:unset;background:#efefef}button[data-v-a9537eda]:hover{background:#01685e;color:#fff}button span[data-v-a9537eda]{font-size:16px;line-height:22px}button.active[data-v-a9537eda]{background:#01685e;color:#fff;cursor:unset}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
