<template>
    <Section width="sm" class="scolarship-form">
        <div 
            v-if="$validateText(slice.primary.text) && !success"
            class="rich-text" 
            v-html="$prismic.asHtml(slice.primary.text)"
        />

        <h3 v-if="success" class="form-success">
            {{ successMessage }}
        </h3>

        <div 
            v-else-if="waitingForResponse" 
            ref="loading" 
            class="loading"
        >
            <i class="fa-solid fa-spinner-third fa-spin fa-2xl" />
        </div>

        <form v-else class="scolarship">
            <div v-if="generalError" class="form-submit-error">
                {{ generalErrorText }}
            </div>
            <dynamic-input 
                v-for="(input, index) in scolarshipInputs"
                :key="index"
                :form-key="input.formKey"
                :label="input.label"
                :type="input.type"
                :placeholder="input.placeholder"
                :error="checkForErrors && !input.validate(input.value)"
                :error-text="input.errorText"
                :is-textarea="input.isTextarea"
                @set="setInput"
            />
            <file-input
                :ref-text="file.refText"
                :type="file.type"
                :form-key="file.formkey"
                :label="file.label"
                :error="checkForErrors && validateFile()"
                :error-text="file.errorText"
                @set="(value) => file.value = value"
            /> 
            <Button 
                background="green"
                class="submit-button"
                :text="$translate('scolarship_form_submit_button_text', 'Skicka in')"
                @click="submit()"
            />
        </form>
    </Section>
</template>

<script>
import DynamicInput from '@/components/slices/apply-for-cards/forms/form-components/DynamicInput.vue';
import FileInput from '@/components/slices/apply-for-cards/forms/form-components/FileInput.vue';
export default {
    components: { DynamicInput, FileInput },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            waitingForResponse: false,
            checkForErrors: false,
            
            success: false,
            successMessage: this.$translate('scolarship_form_success', 'Tack för din ansökan!'),
            
            generalError: false,
            generalErrorText: this.$translate('scolarship_form_general_error', 'Något gick fel, Kontakta vår kundtjänst.'),
            
            scolarshipInputs: {
                name: {
                    formKey: 'name',
                    value: null,
                    label: this.$translate('scolarship_form_name_label', 'Namn'),
                    type: 'text',
                    placeholder: this.$translate('scolarship_form_name_placeholder', 'John Doe'),
                    errorText: this.$translate('scolarship_form_name_error', 'Fyll i fältet'),
                    validate: (input) => {
                        return this.$validateText(input);
                    }
                },
                email: {
                    formKey: 'email',
                    value: null,
                    label: this.$translate('scolarship_form_email_label', 'E-post'),
                    type: 'text',
                    placeholder: this.$translate('scolarship_form_email_placeholder', 'John.doe@example.com'),
                    errorText: this.$translate('scolarship_form_email_error', 'Fyll i en giltig email'),
                    validate: (input) => {
                        const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                        return this.$validateText(input) && validEmailRegex.test(input);
                    }
                },
                school: {
                    formKey: 'school',
                    value: null,
                    label: this.$translate('scolarship_form_school_label', 'Namn på din skola'),
                    type: 'text',
                    placeholder: this.$translate('scolarship_form_school_placeholder', 'Stockholms universitet'),
                    errorText: this.$translate('scolarship_form_school_error', 'Fyll i fältet'),
                    validate: (input) => {
                        return this.$validateText(input);
                    }
                },
                summary: {
                    isTextarea: true,
                    formKey: 'summary',
                    value: null,
                    label: this.$translate('scolarship_form_essay_summary_label', 'Kort sammanfattning av uppsatsen'),
                    type: 'textarea',
                    placeholder: this.$translate('scolarship_form_essay_summary_placeholder', 'Ditt meddelande...'),
                    errorText: this.$translate('scolarship_form_essay_summary_error', 'Fyll i fältet'),
                    validate: (input) => {
                        return this.$validateText(input);
                    }
                },
                motivation: {
                    isTextarea: true,
                    formKey: 'motivation',
                    value: null,
                    label: this.$translate('scolarship_form_motivation_label', 'Varför skall du få stipendiet?'),
                    type: 'textarea',
                    placeholder: this.$translate('scolarship_form_motivation_placeholder', 'Ditt meddelande...'),
                    errorText: this.$translate('scolarship_form_motivation_error', 'Fyll i fältet'),
                    validate: (input) => {
                        return this.$validateText(input);
                    }
                },
            },
            file: {
                formKey: 'file',
                value: null,
                label: this.$translate('scolarship_form_attach_file_label', 'Bifoga uppsatsen'),
                type: 'file',
                errorText: this.$translate('scolarship_form_attach_file_error', 'Ladda upp en giltig fil {allowedFiles}')
                    .replace('{allowedFiles}', ' (doc, docx, pdf)'),
                refText: 'file',
                validate: (input) => {
                    const validFileRegex = /^.*\.(doc|DOC|docx|DOCX|pdf|PDF)$/;
                    return input && validFileRegex.test(input);
                }
            }
        };
    },
    computed: {
        message() {
            return `
            Namn: ${this.scolarshipInputs.name.value}
            Email: ${this.scolarshipInputs.email.value}
            Namn på skola: ${this.scolarshipInputs.school.value}

            Sammanfattning: 
            ${this.scolarshipInputs.summary.value}

            Motivation:
            ${this.scolarshipInputs.motivation.value}
            `;
        },
    },
    methods: {
        setInput(key, value) {
            return this.scolarshipInputs[key].value = value;
        },
        validateInputFields() {
            return Object.values(this.scolarshipInputs).some(input => {
                return ! input.validate(input.value);
            });
        },
        validateFile() {
            return ! this.file.validate(this.file.value?.name);
        },
        submit() {
            this.generalError = false;
            this.checkForErrors = true;
            if (this.validateInputFields() || this.validateFile()) {
                return;
            }

            this.waitingForResponse = true;

            this.$nextTick(() => {
                this.$refs.loading.scrollIntoView({ block: 'center' });
            });

            const data = new FormData();
            data.append('message', this.message);
            data.append('attachment', this.file.value);

            this.$axios
                .post('https://formspree.io/f/mwkgoypr', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(() => {
                    this.success = true;
                })
                .catch(() => {
                    this.checkForErrors = false;
                    this.generalError = true;
                })
                .then(() => {
                    this.waitingForResponse = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
    .scolarship-form::v-deep {
        .rich-text {
            margin-bottom: 1.5rem;
        }

        .form-success {
            display: flex;
            justify-content: center;
        }

        .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 5rem 0;
            i {
                color: $green;
            }
        }
        
        .form-submit-error {
            background-color: $ldarkred; 
            color: darkred;
            padding: 1rem;
            border-radius: 12px;
        }

        .scolarship {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @include border(all, 1px);
            box-shadow: $bxs;
            @include border-radius(25px);
            @include spacing(padding, 6, (top, bottom));
            @include spacing(padding, 4, (left,right));
            @include device(pad) {
                @include spacing(padding, 8);
            }

            label {
                font-weight: bold;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active {
            
                transition: background-color 5000s ease-in-out 0s;
            }

            .submit-button {
                margin-top: 20px;
            }
        }
    }
</style>