<template>
    <div class="container-inner">
        <span class="font-header">Steg 3</span>

        <form class="form-container">
            <select-input
                label="Är du en person i politiskt utsatt ställning?"
                name="is pep"
                form-key="isPep"
                :options="[
                    {
                        name: 'Ja',
                        value: true
                    },
                    {
                        name: 'Nej',
                        value: false
                    }
                ]"
                @set="setInput"
            />

            <div class="summary">
                <span>Vad händer nu?</span>
                <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
            </div>

            <boolean-input
                label="Jag har läst ovan och accepterar villkoren"
                name="accepts"
                form-key="accepts"
                @set="setInput"
            />

            <Button
                background="green"
                text="Ansök"
                size="md"
                :class="{disabled: !enableSubmit}"
                @click="submit()"
            />
        </form>
    </div>
</template>

<script>
import logic from './form-logic.js';
export default {
    mixins: [logic],
    data() {
        return {
            item: {
                isPep: '',
                accepts: false
            }
        };
    },
    computed: {
        enableSubmit() {
            return (this.item.isPep.length && this.item.accepts);
        }
    }
};
</script>

<style lang="scss" scoped>
    .summary {
        span {
            display: block;
            &:first-child {
                font-weight: bold;
                margin-bottom: 10px;
                @include font-size(16px,8px);
            }
            &:last-child {
                @include font-size(14px,8px);
            }
        }
    }
</style>
