<template>
    <Section
        :class="slice.slice_label"
        :width="slice.primary.slice_width"
    >
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="grid">
            <div
                v-for="(box, index) in slice.items"
                :key="index"
                class="grid-box rich-text"
                v-html="$prismic.asHtml(box.box_content)"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .grid {
            @include flex(start,stretch);
            gap: 20px;

            &-box {
                @include border;
                @include border-radius(25px);
                box-shadow: $bxs;
                @include spacing(padding,6);

                @include device(pad) {
                    flex: 1;
                    min-width: calc(50% - 20px);
                }

                h2:first-child,
                h3:first-child,
                h4:first-child,
                h5:first-child
                p:first-child {
                    margin-top: 0;
                }
            }
        }
    }
</style>
