<template>
    <div class="search">
        <Button
            :text="$translate('add_card', 'Lägg till kort')"
            size="md"
            background="green"
            @click="show = true"
        />

        <transition name="fade">
            <div
                v-if="show"
                class="search-open"
            >
                <div class="search-open__popup">
                    <input
                        ref="input"
                        v-model="query"
                        class="search-input"
                        type="text"
                        name="search"
                        :placeholder="$translate('search_cards', 'Sök efter kort')"
                    >
                    <div v-if="query.length > 2">
                        <ul
                            class="search-result"
                        >
                            <li
                                v-for="(card, index) in cardList"
                                :key="index"
                                @click="set(card.id)"
                            >
                                <prismic-image
                                    :img="card.data.image"
                                    w="40"
                                    h="25"
                                />
                                <span>{{ card.data.title }}</span>
                            </li>
                        </ul>
                        <div v-if="cardList.length === 0" class="no-results"> {{ $translate('no_results_found', 'Inga resultat hittades') }}</div>
                    </div>
                </div>

                <div
                    v-if="show"
                    class="search-open__overlay"
                    @click="query = '', show = false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            query: '',
            show: false
        };
    },
    computed: {
        cardList() {
            let cards = Object.values(this.$store.state.cards);
            return cards.filter(card => card.data.title.toLowerCase().includes(this.query.toLowerCase()));
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    },
    methods: {
        set(value) {
            this.$emit('set', value);
        }
    }
};
</script>

<style lang="scss" scoped>
    .search {
        position: relative;
        z-index: 2;
        &-open {
            @include size(100vw,100vh);
            position: fixed;
            left: 0;
            top: 0;
            &__popup {
                @include spacing(padding,4);
                max-width: 100%;
                width: 400px;
                background: $white;
                @include border;
                @include border-radius(20px);
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 200px;
                transform: translateX(-50%);
                input {
                    width: 100%;
                }
                ul {
                    max-height: 300px;
                    overflow: auto;
                    li {
                        padding: 5px;
                        cursor: pointer;
                        @include font-size(14px,8px);
                        @include flex(start);
                        &:hover {
                            color: $purple;
                            text-decoration: underline;
                        }
                        img {
                            height: auto;
                            margin-right: 10px;
                        }
                        span { flex: 1; }
                    }
                }
                .no-results{
                    padding: 10px;
                    @include font-size(14px,8px);
                }
            }
            &__overlay {
                position: fixed;
                @include size(100%);
                top: 0;
                left: 0;
                background: rgba(0,0,0,.5);
            }
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>