<template>
    <div class="container-inner">
        <span class="font-header">Steg 1</span>

        <form class="form-container">
            <slider-input
                label="Önskad kredit *"
                name="key"
                form-key="credit"
                @set="setInput"
            />

            <text-input
                label="Personnummer *"
                name="personal number"
                form-key="personalNumber"
                :allow-only-numbers="true"
                @set="setInput"
            />
            <text-input
                label="Email *"
                name="email"
                form-key="email"
                :error="formError"
                error-text="Ogiltig email."
                @set="setInput"
            />
            <text-input
                label="Telefonnummer *"
                name="phone"
                form-key="phone"
                :allow-only-numbers="true"
                @set="setInput"
            />

            <Button
                background="green"
                text="Kommer snart"
                size="md"
                :class="{disabled: !enableSubmit}"
                @click="$emit('next', item)"
            />
        </form>
    </div>
</template>

<script>
import logic from './form-logic.js';
export default {
    mixins: [logic],
    data() {
        return {
            item: {
                credit: undefined,
                personalNumber: undefined,
                email: undefined,
                phone: undefined
            }
        };
    },
    computed: {
        enableSubmit() {
            // TODO: Andreas fix: validate personalNumber, email, phone
            // return (
            //     this.item.credit &&
            //     this.item.email &&
            //     this.item.personalNumber &&
            //     this.item.phone
            // );
            return false;
        }
    }
};
</script>
