export default {
    data() {
        return {
            questionsData: {
                benefits: {
                    header: this.$translate('guide_question_benefits', 'Vilken förmån är viktigst för dig?'),
                    errorText: this.$translate('guide_question_benefits_error', 'Ange vilken förmån som är viktigast'),
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('petrol_discounts', 'Bensinrabatt'),
                            valueKey: 'fuelDiscount',
                        },
                        {
                            title: this.$translate('bonus_program', 'Bonusprogram'),
                            valueKey: 'bonus',
                        },
                        {
                            title: this.$translate('cashback', 'Cashback'),
                            valueKey: 'cashback',
                        },
                        {
                            title: this.$translate('insurances', 'Försäkringar'),
                            valueKey: 'insurance',
                        },
                        {
                            title: this.$translate('guide_option_credit', 'Kredit'),
                            valueKey: 'credit',
                        },
                        {
                            title: this.$translate('guide_option_other', 'Annat'),
                            valueKey: 'other',
                        },
    
                    ]
                },
                usage: {
                    header: this.$translate('guide_question_usage', 'Vad har du primärt tänkt använda kortet till?'),
                    errorText: this.$translate('guide_question_usage_error', 'Ange vad du tänkt använda kortet till'),
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('guide_option_travel', 'Resor'),
                            valueKey: 'travel',
                        },
                        {
                            title: this.$translate('guide_option_shopping', 'Shopping'),
                            valueKey: 'shopping',
                        },
                        {
                            title: this.$translate('guide_option_partial_payment', 'Delbetalning'),
                            valueKey: 'partialPayment',
                        },
                        {
                            title: this.$translate('guide_option_buffer', 'Buffert'),
                            valueKey: 'buffer',
                        },
                        {
                            title: this.$translate('guide_option_other', 'Annat'),
                            valueKey: 'other',
                        },
                    ]
                },
                avoidFee: {
                    header: this.$translate('guide_question_avoid_fee', 'Vilken utgift vill du helst undvika?'),
                    errorText: this.$translate('guide_question_avoid_fee_error', 'Ange vilken utgift du vill undvika'),
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('annual_fee', 'Årsavgift'),
                            valueKey: 'yearlyFee',
                        },
                        {
                            title: this.$translate('currency_surcharge', 'Valutapåslag'),
                            valueKey: 'exchangeFee',
                        },
                        {
                            title: this.$translate('withdraw_fee', 'Uttagsavgift'),
                            valueKey: 'withdrawalFee',
                        },
                    ]
                },
                priority: {
                    header: this.$translate('guide_question_priority', 'Vad är viktigast för dig?'),
                    errorText: this.$translate('guide_question_priority_error', 'Ange låg kostnad eller förmåner'),
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('guide_option_low_cost', 'Låg ränta & avgifter'),
                            valueKey: 'lowCost',
                        },
                        {
                            title: this.$translate('guide_option_benefits', 'Förmåner'),
                            valueKey: 'benefits',
                        },
                    ]
                },
                mobilePayments: {
                    header: this.$translate('guide_question_mobile_payments', 'Vad för mobilabetalsätt önskar du?'),
                    errorText: this.$translate('guide_question_mobile_payments_error', 'Ange vilket mobilabetalsätt du vill ha'),
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('guide_option_unimportant', 'Oviktigt/Annat'),
                            valueKey: 'other',
                        },
                        {
                            title: this.$translate('guide_option_apple_pay', 'Apple pay'),
                            valueKey: 'applePay',
                        },
                        {
                            title: this.$translate('guide_option_samsung_pay', 'Samsung pay'),
                            valueKey: 'samsungPay',
                        },
                        {
                            title: this.$translate('guide_option_google_pay', 'Google pay'),
                            valueKey: 'googlePay',
                        },
                    ]
                },
                paymentRemarks: {
                    header: this.$translate('guide_question_payment_remarks', 'Har du betalningsanmärkningar?'),
                    errorText: this.$translate('guide_question_payment_remarks_error', 'Ange om du har betalningsanmärkninar eller inte'),
                    type: 'buttons',
                    activeValue: '',
                    options: [
                        {
                            title: this.$translate('yes', 'Ja'),
                            valueKey: 'yes',
                        },
                        {
                            title: this.$translate('no', 'Nej'),
                            valueKey: 'no',
                        },
                    ]
                },
            },
            personalInfoData: {
                income: {
                    type: 'number',
                    label: this.$translate('guide_monthly_income_label', 'Månadsinkomst'),
                    tooltip: this.$translate('guide_monthly_income_tooltip', 'Vissa kreditkort har krav på en viss inkomst, därför behöver vi veta din månadsinkomst för att bara rekommendera kort du kan ansöka om'),
                    formKey: 'monthlyIncome',
                    placeholder: '0',
                    onlyNumbers: true,
                    value: null,
                    validate: () => true             
                },
                birthYear: {
                    type: 'number',
                    label: this.$translate('guide_birth_year_label', 'Födelseår'),
                    tooltip: this.$translate('guide_birth_year_tooltip', 'Olika kreditkort har olika ålderskrav, därför behöver vi veta din ålder för att bara rekommendera kort du kan ansöka om'),
                    formKey: 'birthYear',
                    placeholder: this.$translate('guide_birth_year_placeholder', 'E.g. 1982'),
                    value: null,
                    errorText: this.$translate('guide_birth_year_error', 'Fyll i året du är född'),
                    validate: (input) => {
                        return input?.length === 4;
                    }
                },
                email: {
                    type: 'email',
                    label: this.$translate('guide_email_label', 'Email'),
                    formKey: 'email',
                    placeholder: this.$translate('guide_email_placeholder', 'E.g. john.doe@gmail.com'),
                    value: null,
                    errorText: this.$translate('guide_email_error', 'Fyll i en giltig email'),
                    validate: (input) => {
                        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                        return input && reg.test(input);
                    }
                },
                phone: {
                    type: 'number',
                    onlyNumbers: true,
                    label: this.$translate('guide_phone_label', 'Telefonnummer'),
                    formKey: 'phoneNumber',
                    placeholder: '',
                    value: null,
                    errorText: this.$translate('guide_phone_error', 'Fyll i ett giltigt telefonnummer'),
                    validate: this.$validatePhoneNumber
                },
            },
        };
    },
};