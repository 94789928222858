// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-1722e4aa]{font-family:\"Open Sans\"}.font-header[data-v-1722e4aa]{font-family:\"Hero\"}.rich-text__img-box[data-v-1722e4aa]{position:relative}.rich-text__img-box[data-v-1722e4aa] img{position:relative;width:100%;height:auto;border-radius:20px;box-shadow:0 10px 15px rgba(169,177,200,.25)}.rich-text__img-box[data-v-1722e4aa] .content{width:100%;margin-top:10px;text-align:center}.rich-text__img-box[data-v-1722e4aa] .content p{font-size:14px;line-height:20px;font-style:italic}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
