// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-6497dd3a]{font-family:\"Open Sans\"}.font-header[data-v-6497dd3a]{font-family:\"Hero\"}.input-text[data-v-6497dd3a]{font-size:14px;line-height:20px}.input-text[data-v-6497dd3a] a{color:#01685e;font-weight:700}.input-text[data-v-6497dd3a] a:hover{text-decoration:underline}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
