<template>
    <div class="read-time font-text">
        <i class="fa-regular fa-clock" />

        <span class="read-time__minuets">{{ formatReadMinuets }} min </span>
    </div>
</template>

<script>
let numeral = require('numeral');
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        readTime() {
            let richTextArr = this.data.data.body.filter(item => item.slice_type === 'rich_text');
            let wordCount = 0;

            for (let i = 0; i < richTextArr.length; i++) {
                let itemsArr = richTextArr[i].items;
                wordCount = wordCount + this.wordCounter(itemsArr);
            }

            return wordCount;
        },
        formatReadMinuets() {
            return numeral(this.readTime).format('0');
        }
    },
    methods: {
        wordCounter(text) {
            for (let i = 0; i < text.length; i++) {
                let words = this.$prismic.asText(text[i].content).split(' ');
                return words.length / 100;
            }
        }
    }
};
</script>
