<template>
    <Section
        :class="slice.slice_label"
        :width="slice.primary.slice_width"
    >
        <div
            class="cta-box rich-text"
            :class="slice.primary.bg"
            v-html="$prismic.asHtml(slice.primary.box_content)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.cta-box {
    @include border(all,1px);
    box-shadow: $bxs;
    @include border-radius(25px);
    @include spacing(padding,8);

    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child
    p:first-child {
        margin-top: 0;
    }
}

.cta-box.white { background: $white; }
.cta-box.gray { background: $graybg; }
.cta-box.green { background: $greenbg; }
.cta-box.purple { background: $purplebg; }
.cta-box.gray-blue {
    background: $grayblue; 
    color: $white;

    ::v-deep {
        ol li:after {
            color: $white;
        }
        ul li:after {
            background: $white;
        }
        a {
        color: $white;
        text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }
}
</style>
