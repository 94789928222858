<template>
    <div
        :class="type"
        class="container-box"
    >
        <div class="container-box__inner">
            <prismic-image
                :img="image"
            />
        </div>

        <div class="container-box__inner">
            <div class="inner">
                <span class="font-header">{{ title }}</span>
                <span
                    v-if="uspText"
                    class="usp-text font-text"
                >
                    {{ uspText }}
                </span>

                <div>
                    <nuxt-link
                        :to="$prismic.linkResolver(link)"
                        class="btn btn-md btn-reset"
                    >   
                        {{ $translate('cta_read_more', 'Läs mer') }}
                        <i class="fa-solid fa-arrow-right" />
                    </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true
        },
        image: {
            type: Object,
            required: true
        },
        uspText: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: 'default'
        },
    },
};
</script>

<style lang="scss" scoped>
    .container-box {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .container-box__inner:last-child {
            @include spacing(padding,6);

            .btn { text-align: left; }
        }
    }

    // Layouts
    .container-box.default {
        @include grid(2,0);

        .container-box__inner:last-child {
            @include flex(start);
            @include spacing(padding,6);


            .inner {
                @include grid(1,10px);
            }
        }
    }

    .container-box.highlighted {
        .container-box__inner:last-child {
            @include flex;
            .inner {
                @include grid(1,20px);
            }
        }
    }
</style>
