<template>
    <li class="card-list__item">
        <div class="wrapper">
            <div class="card-img">
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <prismic-image
                        :img="card.data.image"
                        w="200"
                        h="125"
                        class="list-item-card-image"
                    />
                    <span class="card-title font-header">
                        {{ card.data.title }}
                    </span>
                </nuxt-link>
            </div>
            <div
                class="rich-text card-content"
                v-html="$prismic.asHtml(content)"
            />
        </div>

        <ButtonTarget
            :link="card"
            background="orange"
            size="sm"
            class="button"
        />
        <div v-if="model.complianceExample" class="text-compliance">
            {{ model.complianceExample }}
        </div>
    </li>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
            default: undefined
        },
        content: {
            type: Array,
            required: true,
            default: () => {}
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.id];
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-list__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        box-shadow: $bxs;
        @include spacing(padding, 4);
        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;
            .card-img a {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }
            .card-content::v-deep {
                text-align: left;
                p:first-child{ margin-top: 0!important; }
            }
            
            @include device(mobile) {
                .list-item-card-image {
                    height: 100px !important;
                    width: 150px !important;
                }
            }
        }
        .button {
            width: 100%;
        }
    }
</style>
