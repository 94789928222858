<template>
    <Section width="lg">
        <div class="header-section">
            <div
                v-if="$validateText(slice.primary.title)"
                class="header-title"
                v-html="$prismic.asHtml(slice.primary.title)"
            />
            <div
                v-if="$validateText(slice.primary.teaser)"
                class="rich-text"
                v-html="$prismic.asHtml(slice.primary.teaser)"
            />
        </div>
        <div class="pros-cons-container" :class="{'one-container': !pros.length || !cons.length}">
            <pros-cons-card 
                v-if="pros.length"
                image="/pros-image.png"
                :title="slice.primary.pros_title"
                :teaser="slice.primary.pros_teaser"
                :items="pros"
            />
            <pros-cons-card 
                v-if="cons.length"
                image="/cons-image.png"
                :title="slice.primary.cons_title"
                :teaser="slice.primary.cons_teaser"
                :items="cons"
            />
        </div>
    </Section>
</template>

<script>
import ProsConsCard from '@/components/ProsConsCard.vue';
export default {
    components: { ProsConsCard },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        pros() {
            return this.slice.items.filter(pro => pro.type === 'pro' && pro.text);
        },
        cons() {
            return this.slice.items.filter(con => con.type === 'con' && con.text);
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        .section-inner {
            
            .header-section {
                @include spacing(margin,8,bottom);
                .header-title {
                    @include spacing(margin,4,bottom);
                }
            }

            .pros-cons-container.one-container {
                @include grid(1,20px);
            }
            .pros-cons-container {
                @include grid(2,20px);

                @include device(mobile) {
                    @include grid(1,40px);
                }
                
                .pros-cons-section {
                    @include spacing(padding, 10);
                    @include border-radius(25px);
                    @include border(all,1px);
                    box-shadow: $bxs;

                    @include device(mobile) {
                        @include spacing(padding, 8);
                    }
                
                    .pros-cons-section-header {
                        display: flex;
                        align-items: center;
                        @include spacing(margin,4,bottom);
                        .pros-cons-title {
                            margin-left: 0.5rem;
                            h2, h3, h4 {
                                font-size: calc(1.25625rem + .075vw) !important;
                            }
                        }
                    }
                    .pros-cons-teaser {
                        @include spacing(margin,4,bottom);
                    }
                    .pros-cons-list {
                        @include grid(1,20px);
                        li {
                            @include font-size(16px, 10px);
                            @include flex(start, start);

                            i {
                                font-size: 17px;
                                margin-top: 5px;
                            }
                            span {
                                flex: 1;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
