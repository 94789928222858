// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-4b722bae]{font-family:\"Open Sans\"}.font-header[data-v-4b722bae]{font-family:\"Hero\"}.trust-us[data-v-4b722bae]{margin-top:1rem}.trust-us[data-v-4b722bae] .expand-row__button.expand,.trust-us[data-v-4b722bae] .expand-row__content.expand{background-color:#f9fafc}.trust-us[data-v-4b722bae] .expand-row__content{padding:0 40px;margin-bottom:20px;border-bottom-left-radius:10px;border-bottom-right-radius:10px}@media screen and (max-width:600px){.trust-us[data-v-4b722bae] .expand-row__content{padding:0 32px;margin-bottom:16px}}@media screen and (min-width:mobile){.trust-us[data-v-4b722bae] .expand-row__content{padding:0 32px;margin-bottom:16px}}.trust-us[data-v-4b722bae] .trust-us-expander .expand-row__button{justify-content:flex-start;flex-wrap:nowrap;border-top-left-radius:10px;border-top-right-radius:10px;padding:20px 40px}@media screen and (max-width:600px){.trust-us[data-v-4b722bae] .trust-us-expander .expand-row__button{padding:16px 32px}}@media screen and (min-width:mobile){.trust-us[data-v-4b722bae] .trust-us-expander .expand-row__button{padding:16px 32px}}.trust-us[data-v-4b722bae] .trust-us-expander .expand-row__button i.left-icon{color:#00c9a7;margin-right:.5rem;transition:none;transform:rotate(0)}.trust-us[data-v-4b722bae] .trust-us-expander .expand-row__button i.right-icon{margin-left:1rem}.trust-us[data-v-4b722bae] .trust-us-rich-text p:first-child{margin:0}.trust-us[data-v-4b722bae] .trust-us-rich-text p:last-child{margin-bottom:20px}@media screen and (max-width:600px){.trust-us[data-v-4b722bae] .trust-us-rich-text p:last-child{margin-bottom:16px}}@media screen and (min-width:mobile){.trust-us[data-v-4b722bae] .trust-us-rich-text p:last-child{margin-bottom:16px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
