// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-81c382d8]{font-family:\"Open Sans\"}.font-header[data-v-81c382d8]{font-family:\"Hero\"}.question-container[data-v-81c382d8]{padding:32px;box-sizing:border-box;border:2px solid #01685e;border-radius:5px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;flex-direction:column;grid-gap:20px;gap:20px;box-shadow:0 10px 15px rgba(169,177,200,.25)}.question-header[data-v-81c382d8]{font-size:20px;line-height:26px;word-break:break-word;font-weight:700}@media screen and (min-width:600px){.question-header[data-v-81c382d8]{font-size:24px;line-height:30px}}@media screen and (min-width:pad){.question-header[data-v-81c382d8]{font-size:24px;line-height:30px}}.question-error[data-v-81c382d8],.question-options[data-v-81c382d8]{width:100%}.question-error span[data-v-81c382d8]{color:#8b0000;font-size:14px;line-height:20px;background:rgba(179,0,0,.15);padding:15px;border:1px solid #8b0000;border-radius:2px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
