// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-3d69d312]{font-family:\"Open Sans\"}.font-header[data-v-3d69d312]{font-family:\"Hero\"}.section-highlight-stats[data-v-3d69d312]{padding:0;background-color:#01685e;margin:48px 0}.stat-container[data-v-3d69d312]{display:flex;flex-wrap:wrap;margin:0;text-align:center}.stat-inner-container[data-v-3d69d312]{flex:0 0 auto;width:33.33333333%;padding:3rem 0}.col-divider[data-v-3d69d312]>:not(:first-child){position:relative;box-sizing:border-box}.col-divider[data-v-3d69d312]>:not(:first-child):before{position:absolute;top:0;left:0;width:.0625rem;height:100%;background-color:hsla(0,0%,100%,.1);content:\"\"}.col-divider-rotated[data-v-3d69d312]>:not(:first-child):before{transform:rotate(20deg)}.highlight-stat[data-v-3d69d312]{font-size:42px;font-weight:700;color:#fff}.stat-title[data-v-3d69d312]{margin-bottom:1.5rem;color:#fff;font-weight:bolder;font-family:inherit}@media screen and (max-width:600px){.section-highlight-stats[data-v-3d69d312]{padding:0}.stat-container[data-v-3d69d312]{flex-direction:column}.stat-inner-container[data-v-3d69d312]{width:100%;padding:2rem 0}.col-divider[data-v-3d69d312]>:not(:first-child):before{width:100%;height:.0625rem;transform:translateX(-50%);left:50%}.highlight-stat[data-v-3d69d312]{font-size:36px}}@media screen and (min-width:mobile){.section-highlight-stats[data-v-3d69d312]{padding:0}.stat-container[data-v-3d69d312]{flex-direction:column}.stat-inner-container[data-v-3d69d312]{width:100%;padding:2rem 0}.col-divider[data-v-3d69d312]>:not(:first-child):before{width:100%;height:.0625rem;transform:translateX(-50%);left:50%}.highlight-stat[data-v-3d69d312]{font-size:36px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
