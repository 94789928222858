<template>
    <div class="list-filter">
        <div
            v-if="enableSearch"
            class="input"
        >
            <input
                ref="input"
                v-model="query"
                type="text"
                name="search"
                class="search"
                :placeholder="$translate('search', 'Sök')"
            >

            <i
                v-if="query.length"
                class="fa-regular fa-xmark fa-clear"
                @click="removeQuery()"
            />
        </div>

        <div class="filter">
            <button
                class="filter-button btn btn-green-border"
                :class="{active: activeFilter === 'alla'}"
                @click="$emit('set', 'alla')"
            >
                {{ $translate('all', 'Alla') }}
            </button>

            <button
                v-for="filter in filters"
                :key="filter.id"
                class="filter-button btn btn-green-border"
                :class="{active: activeFilter === filterData(filter.id).data.category}"
                @click="$emit('set', filter)"
            >
                {{ filterData(filter.id).data.category }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeFilter: {
            type: String,
            required: false,
            default: undefined
        },
        filterKey: {
            type: String,
            required: true,
            default: undefined
        },
        enableSearch: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            query: ''
        };
    },
    computed: {
        filters() {
            return this.$store.state[this.filterKey];
        }
    },
    watch: {
        query() {
            this.$emit('query', this.query);
        }
    },
    mounted() {
        if (this.enableSearch) {
            this.$refs.input.focus();
        }
    },
    methods: {
        filterData(id) {
            return this.$store.state[this.filterKey][id];
        },
        removeQuery() {
            this.query = '';
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
.input {
    position: relative;

    .search {
        @include border(bottom,4px, $green);
        background: $graybg;
        padding: 15px;
        outline: none;
        font-size: 18px;
        font-family: $fontText;
        width: 100%;
        margin-bottom: 20px;

        &:focus {
            border-color: $green;
        }
    }

    i {
        position: absolute;
        right: 10px;
        top: 13px;
        font-size: 28px;
        color: $font!important;
        cursor: pointer;
    }
}

.filter {
    background: $graybg;
    padding: 10px;
    @include flex(start);
    gap: 10px;

    &-button {
        // margin: 5px;
        padding: 10px 15px;
        background: $white;
        cursor: pointer;
        box-shadow: none;
        transition: none;
    }

    &-button.active {
        background: $green;
        color: $white;
    }
}
</style>
