<template>
    <Section width="md"> 
        <section-header
            :title="slice.primary.title"
        />
        <ul>
            <li v-for="(quote, index) in slice.items" :key="index">
                <div class="quote-container" :class="slice.primary.quotes_background">
                    <div>
                        <div v-if="quote.rating && quote.rating !== 'hide'" class="rating-container">
                            <i  
                                v-for="(star, starIndex) in 5" 
                                :key="starIndex"
                                alt="rating"
                                class="fa-sharp rating"
                                :class="starIndex < quote.rating ? 'fa-solid fa-star' : 'fa-regular fa-star'"
                            />
                        </div>
                        <div class="quote-text" v-html="$prismic.asHtml(quote.quote_text)" />
                    </div>
                    <span v-if="$validateText(quote.quote_by)" class="quote-author">
                        - {{ quote.quote_by }}
                    </span>
                </div>
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
        }
    }
};
</script>

<style lang="scss" scoped>
    ul {
        display: flex;
        gap: 20px;
        row-gap: 35px;
        flex-wrap: wrap;

        li {
            flex: 1;
            min-width: 100%;
            @include device(pad) {
                min-width: calc(50% - 20px);
            }
        }

        .quote-container {
            padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include border();
            border-color: transparent;
            background: $graybg;
            @include border-radius(25px);
            position: relative;
            &:before {
                content: "";
                width: 0px;
                height: 0px;
                position: absolute;
                border-left: 10px solid $graybg;
                border-right: 10px solid transparent;
                border-top: 10px solid $graybg;
                border-bottom: 10px solid transparent;
                left: 19px;
                bottom: -19px;
            }
            .quote-text {
                font-style: italic;
                margin: 0.5rem 0;
            }
            .quote-author {
                display: block;
                margin-top: 10px;
                @include font-size(16px);
                font-weight: normal;
            }
            
            .rating-container {
                display: flex;
                @include spacing(margin, 1, top);
                .rating {
                    @include size(20px);
                }
            }

            &.gray {
                background: $graybg;
                &:before {
                    border-left-color: $graybg;
                    border-top-color: $graybg;
                }
            }
            &.purple {
                background: $purplebg;
                &:before {
                    border-left-color: $purplebg;
                    border-top-color: $purplebg;
                }
            }
            &.orange {
                background: $orangebg;
                &:before {
                    border-left-color: $orangebg;
                    border-top-color: $orangebg;
                }
            }
            &.light-green {
                background: $greenbg;
                &:before {
                    border-left-color: $greenbg;
                    border-top-color: $greenbg;
                }
            }
            &.green {
                background: $green;
                color: $white;
                &:before {
                    border-left-color: $green;
                    border-top-color: $green;
                }
            }
        }

    }
</style>
