<template>
    <Section 
        :class="slice.slice_label"
        width="lg"
    >
        <section-header
            id="cards-header"
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <ul class="lenders-list">
            <Lender 
                v-for="lender in lenders"
                :key="lender.id"
                :lender="lender"
            />
        </ul>
    </Section>
</template>

<script>
import Lender from '@/components/slices/list/list-item/ListItemLender.vue';
export default {
    components: {
        Lender,
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    computed: {
        lenders() {
            return this.slice.items
                .map(iterator => iterator.lender)
                .filter(lender => lender.id);
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .lenders-list {
            width: 100%;
            @include grid(1, 30px);
        }
    }
</style>
