// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-3ce9138a]{font-family:\"Open Sans\"}.font-header[data-v-3ce9138a]{font-family:\"Hero\"}.search[data-v-3ce9138a]{border:0;box-sizing:border-box;border-bottom:4px solid #01685e;background:#f9fafc;padding:15px;outline:none;font-size:18px;font-family:\"Open Sans\"}.search[data-v-3ce9138a]:focus{border-color:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
