<template>
    <Section width="md">
        <div class="container bank-cooperations">
            <div 
                v-if="$prismic.asHtml(slice.primary.title).length"
                class="bank-cooperations-title"
                v-html="$prismic.asHtml(slice.primary.title)" 
            />
            <div class="bank-cooperations-box">
                <div 
                    v-for="(item, index) in items" 
                    :key="index"
                >
                    <nuxt-link 
                        v-if="item.link.id"
                        :to="$prismic.linkResolver(item.link)"
                    >
                        <prismic-image
                            class="bank-cooperations-img"
                            :img="item.image"
                            w="200"
                            h="55"
                        />
                    </nuxt-link>
                    <prismic-image
                        v-else
                        class="bank-cooperations-img"
                        :img="item.image"
                        w="200"
                        h="55"
                    />
                </div>
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.slice.items.filter(item => item.image.url);
        }
    }
};
</script>
<style lang='scss' scoped>
    .bank-cooperations {

        .bank-cooperations-title {
            text-align: center;
            margin-bottom: 2rem;
            @include device(pad) {
                margin-bottom: 3rem;
            }
        }

        div.bank-cooperations-box {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            row-gap: 2rem;
            @include device(pad) {
                justify-content: center;
                row-gap: 3rem;
            }
        
            div {
                flex: 0 0 auto;
                width: 50%;
                @include device(pad) {
                    width: 33.33333333%;
                }
                .bank-cooperations-img {
                    object-fit: contain;
                    max-width: 75%;
                    @include device(pad) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
</style>