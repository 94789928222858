<template>
    <Section>
        <div class="container">
            <img
                src="/quote.webp"
                alt="Kortio"
                loading="lazy"
            >
        </div>

        <div class="container">
            <span class="h2">{{ slice.primary.quote }}</span>
            <span class="font-text">- {{ slice.primary.quote_by }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $graybg;

        .section-inner {
            @include flex;
            width: 1600px;

            img {
                @include size(75px);

                @include device(pad) {
                    @include size(150px);
                }
            }

            .container {
                @include device(mobile) {
                    width: 100%;
                    &:first-child { margin-bottom: 20px; }
                }
                @include device(pad) {
                    &:first-child {
                        text-align: center;
                        flex: 1;
                        padding-right: 40px;
                     }
                    &:last-child { 
                        flex: 4; 
                        span:last-child {
                            margin-top: 20px;
                            display: block;
                            @include font-size(18px);
                            @include device(pad) {
                                @include font-size(20px);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
