<template>
    <div class="section section-highlight-stats">
        <div class="section-inner section-width-md">
            <div class="stat-container width-full col-divider col-divider-rotated">
                <div 
                    v-for="(item, index) in slice.items" 
                    :key="index"
                    class="stat-inner-container"
                >
                    <div class="stat-title">{{ item.title }}</div>
                    <div class="highlight-stat">{{ item.statistic }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
    .section-highlight-stats {
        padding: 0;
        background-color: $green;
        margin: 48px 0;
    }
    .stat-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0;    
        text-align: center;
    }
    .stat-inner-container {
        flex: 0 0 auto;
        width: 33.33333333%;
        padding: 3rem 0;
    }
    .col-divider>:not(:first-child) {
        position: relative;
        box-sizing: border-box;
    }
    .col-divider>:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.0625rem;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        content: "";
    }
    .col-divider-rotated>:not(:first-child)::before {
        transform: rotate(20deg);
    }
    .highlight-stat {
        font-size: 42px;
        font-weight: bold;
        color: #fff;
    }
    .stat-title {
        margin-bottom: 1.5rem;
        color: #fff;
        font-weight: bolder;
        font-family: inherit;
    }

    @include device(mobile) {
        .section-highlight-stats {
            padding: 0;
        }
        .stat-container {
            flex-direction: column;
        }
        .stat-inner-container {
            width: 100%;
        }
        .stat-inner-container {
            padding: 2rem 0;
        }
        .col-divider>:not(:first-child)::before {
            width: 100%;
            height: 0.0625rem;
            transform: translateX(-50%);
            left: 50%;
        }
        .highlight-stat {
            font-size: 36px;
        }
    }
</style>