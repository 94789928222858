<template>
    <div class="section">
        <div class="section-width-md">
            <div v-if="submitSuccess" class="success-message">
                <i class="fa-regular fa-circle-check success-icon" />
                <h5 class="success-title">{{ newsletter.success_message }}</h5>
            </div>
            <div v-else-if="loading" class="loading">
                <i class="fa-duotone fa-spinner-third fa-spin" />
            </div>
            <div v-else class="newsletter-bg" :class="{'fadeout-form': fadeoutForm}">
                <div class="newsletter-form" :class="{ 'newsletter-no-title-layout': !hasTitle }">
                    <div class="newsletter-container">
                        <h3 v-if="hasTitle">{{ $prismic.asText(newsletter.h3) }}</h3>
                        <p v-if="newsletter.p && hasTitle">{{ newsletter.p }}</p>
                        <div class="newsletter-inputs-row">
                            <div class="newsletter-input-group">
                                <input v-model="email" class="newsletter-email" type="email" placeholder=" ">
                                <label class="newsletter-email-label">{{ newsletter.email_label }}</label>
                            </div>
                            <button class="btn btn-sm btn-green newsletter-button" @click.prevent="submit">
                                {{ newsletter.submit_button_text }}
                            </button>
                        </div>
                        <button class="btn btn-sm btn-green newsletter-button-mobile" @click.prevent="submit">
                            {{ newsletter.submit_button_text }}
                        </button>
                        <div v-if="emailError" class="newsletter-error">
                            <i class="fa-solid fa-triangle-exclamation error-icon" /> {{ newsletter.error_message }}
                        </div>
                    </div>
                    <div v-if="newsletter.img.url && hasTitle" class="image-container">
                        <prismic-image class="newsletter-img" :img="newsletter.img" w="125" h="175" />
                    </div>
                </div>
                <div class="disclaimer rich-text" :class="{ 'newsletter-no-title-layout': !hasTitle }" v-html="$prismic.asHtml(newsletter.footer_p)" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            email: '',
            submitSuccess: false,
            
            loading: false,
            emailError: false,
            preventDoubleClick: false,
            fadeoutForm: false,
        };
    },
    computed: {
        newsletter() {
            return this.slice.primary;
        },
        hasTitle() {
            return this.newsletter.h3[0] && this.newsletter.h3[0].text.length > 0; 
        }
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.preventDoubleClick) {
                return;
            }

            if (! this.email.match(validEmailRegex)) {
                return this.emailError = true;
            }
            this.fadeoutForm = true;
            this.preventDoubleClick = true;
            setTimeout(() => this.loading = true, 300);
            
            this.$axios
                .post('https://api.broker.compary.com/users/', {
                    email: this.email,
                    guide_id: process.env.BROKER_GUIDE_ID,
                    tracking: this.$tracking.getTrackingObject(),
                    redirect_url: null
                })
                .then(() => {
                    this.email = '';
                    this.submitSuccess = true;
                    this.preventDoubleClick = true;
                    this.loading = true;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .newsletter-bg {
        opacity: 1;
        background-color: $green;
        background-image: -webkit-linear-gradient(150deg, $greendark 35%, $green 35%);
        padding: 2rem 4rem;
        color: $white;
        border-radius: 24px;
    }
    .fadeout-form {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: form;
    }
    .newsletter-form {
        display: flex;
        justify-content: space-between;
    }
    .newsletter-container {
        flex-grow: 1;
    }
    .newsletter-container h3 {
        margin: 0 0 1rem 0;
    }
    .newsletter-container p {
        margin: 0 0 3rem 0;
    }
    .newsletter-inputs-row {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        padding: 0.6125rem 0.6125rem;
        box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
        border-radius: 24rem;
        height: 37px;
        margin: 1rem 0 0 0;
    }
    .newsletter-no-title-layout {
        width: 75%!important;
        margin: auto !important;
    }
    .newsletter-input-group {
        position: relative;
        flex-grow: 1;
        padding-right: 0.5rem;
    }
    .newsletter-email {
        position: relative;
        border: 0;
        background-color: $white;
        flex-grow: 1;
        min-width: 100%;
    }
    .newsletter-email-label {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #999;
        pointer-events: none;
        z-index: 9;
        transition: 0.3s;
    }
     .newsletter-email:focus ~ label, .newsletter-email:not(:placeholder-shown) ~ label {
        transform: translateX(-5%) translateY(-140%);
        font-size: .75em;
        padding: 0 0.3rem;
    }
    .newsletter-email:focus-visible {
        border: 0;
    }
    .newsletter-button,
    .newsletter-button-mobile {
        border-radius: 24px;
    }
    .newsletter-button-mobile {
        display: none;
    }
    .image-container {
        width: 155px;
        transform: scaleX(1);
    }
    .newsletter-img {
        position: absolute;
        transform: rotate(-5deg);
        top: -10px;
        right: 20px;
        height: 170px;
        width: 120px;
    }
    .disclaimer ::v-deep p {
        margin-top: 1.5rem;
        font-size: 12px;
        font-weight: lighter;
        text-align: center;
        line-height: 0.9rem;
        color: #f8f8f8;
    }
    .disclaimer ::v-deep p > a {
        font-weight: bold;
        text-decoration: underline;
        color: $white;
    }
    .loading {
        text-align: center;
        font-size: 40px;
        color: #333;
        animation-delay: 500ms;
    }
    .success-icon {
        font-size: 40px;
        margin: 0 0 1rem 0;
    }
    .success-message {
        opacity: 0;
        color: $green;
        text-align: center;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: success-message;
    }
    .error-icon {
        font-size: 18px;
    }
    .newsletter-error {
        color: red; 
        font-weight: bold;
        font-size: 14px;
        padding: 1rem 1rem 0 1rem;
    }
    @keyframes success-message {
        from {
            transform: translate3d(0,40px,0)
        }

        to {
            transform: translate3d(0,0,0);
            opacity: 1
        }
    }
    @keyframes form {
        from {
            transform: translate3d(0,0,0);
        }

        to {
            transform: translate3d(0,40px,0);
            opacity: 0;
        }
    }
   @media screen and (max-width: 768px) {
    .newsletter-bg {
        background-image: -webkit-linear-gradient(150deg, $greendark 35%, $green 35%) !important;
    }
    .newsletter-form {
        flex-wrap: wrap;
    }
    .newsletter-container {
        text-align: center;
    }
    .newsletter-container p {
        margin: 0 0 2rem 0;
    }
    .newsletter-button {
        display: none;
    }
    .newsletter-button-mobile {
        display: block;
        width: 100%;
        margin: 1rem 0;
        padding: 1rem 0;
        background-color: $green;
    }
    .newsletter-bg {
        padding: 32px 24px;
    }
    .newsletter-img {
        display: none;
    }
    .newsletter-error {
        padding: 0.5rem 1rem 0 1rem ;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
 
    transition: background-color 5000s ease-in-out 0s;
}
</style>