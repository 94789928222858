// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-7ba32d68]{font-family:\"Open Sans\"}.font-header[data-v-7ba32d68]{font-family:\"Hero\"}.summary span[data-v-7ba32d68]{display:block}.summary span[data-v-7ba32d68]:first-child{font-weight:700;margin-bottom:10px;font-size:16px;line-height:24px}.summary span[data-v-7ba32d68]:last-child{font-size:14px;line-height:22px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
