import { orderBy } from 'lodash';

export default {
    data() {
        return {
            scoreCalculations: {
                benefits: {
                    fuelDiscount: (model) => {
                        let score = 0;
                        model.hasFuelOffers && (score += 10);
                        model.hasCashback && (score += 5);
                        return score;
                    },
                    bonus: (model) => {
                        let score = 0;
                        model.hasBonusProgram && (score += 10);
                        model.hasCashback && (score += 5);
                        model.hasAirlineBonus && (score += 5);
                        model.hasFuelOffers && (score += 2);
                        model.hasDiscountProgram && (score += 2);
                        return score;
                    },
                    cashback: (model) => {
                        let score = 0;
                        model.hasCashback && (score += 10);
                        (! model.hasMonthlyCashbackLimit) && (score += 10);
                        return score;
                    },
                    insurance: (model) => {
                        let score = 0;
                        model.hasTravelInsurance && (score += 5);
                        model.hasComprehensiveInsurance && (score += 3);
                        model.hasCancellationProtection && (score += 3);
                        model.hasDeductibleInsurance && (score += 3);
                        model.hasDeliveryInsurance && (score += 1);
                        model.hasHoleInOneInsurance && (score += 1);
                        model.hasIdTheftInsurance && (score += 1);
                        model.hasLostKeyInsurance && (score += 1);
                        model.hasWrongFuelInsurance && (score += 1);
                        model.hasPriceGuarantee && (score += 1);
                        model.hasPurchaseProtection && (score += 1);
                        model.hasExtendedWarranty && (score += 1);
                        return score;
                    },
                    credit: (model) => {
                        let score = 0;
                        model.isCredit && (score += 50);
                        return score;
                    },
                    other: () => 0
                },
                usage: {
                    travel: (model) => {
                        let score = 0;
                        model.hasTravelInsurance && (score += 5);
                        model.hasAirlineBonus && (score += 5);
                        model.currencyExchangeFeeType === 'free' && (score += 3);
                        model.hasLoungeAccess && (score += 2);
                        model.hasConciergeService && (score += 2);
                        return score;
                    },
                    shopping: (model) => {
                        let score = 0;
                        model.isCredit && (score += 5);
                        model.hasCashback && (score += 5);
                        model.hasDiscountProgram && (score += 2);
                        return score;
                    },
                    partialPayment: (model) => {
                        let score = 0;
                        model.minInterestPossible < 16 && (score += 5);
                        model.minInterestPossible < 22 && (score += 5);
                        model.interestFreeDays > 45 && (score += 5);
                        model.yearlyCost < 100 && (score += 5);
                        model.isCredit && (score += 25);
                        return score;
                    },
                    buffer: (model) => {
                        let score = 0;
                        model.yearlyCost < 100 && (score += 10);
                        model.isCredit && (score += 25);
                        return score;
                    },
                    other: () => 0
                },
                avoidFee: {
                    yearlyFee: (model) => {
                        let score = 0;
                        model.firstYearCost === 0 && (score += 2.5);
                        model.yearlyCost < 1000 && (score += 2.5);
                        model.yearlyCost < 500 && (score += 2.5);
                        model.yearlyCost === 0 && (score += 2.5);
                        return score;
                    },
                    exchangeFee: (model) => {
                        let score = 0;
                        model.currencyExchangeFeeType === 'free' && (score += 10);
                        return score;
                    },
                    withdrawalFee: (model) => {
                        let score = 0;
                        model.withdrawalFeeType === 'free' && (score += 10);
                        return score;
                    },
                },
                priority: {
                    lowCost: (model) => {
                        let score = 0;
                        model.isCredit && (score += 20);
                        model.minInterestPossible < 15 && (score += 5);
                        model.minInterestPossible < 20 && (score += 5);
                        model.yearlyCost === 0 && (score += 5);
                        return score;
                    },
                    benefits: (model) => {
                        let score = 0;
                        model.hasBonusProgram && (score += 5);
                        model.hasCashback && (score += 5);
                        model.hasDiscountProgram && (score += 5);
                        model.hasFuelOffers && (score += 3);
                        model.hasLoungeAccess && (score += 3);
                        model.hasConciergeService && (score += 3);
                        return score;
                    },
                },
                mobilePayments: {
                    applePay: (model) => {
                        let score = 0;
                        model.hasApplePay && (score += 10);
                        return score;
                    },
                    samsungPay: (model) => {
                        let score = 0;
                        model.hasSamsungPay && (score += 10);
                        return score;
                    },
                    googlePay: (model) => {
                        let score = 0;
                        model.hasGooglePay && (score += 10);
                        return score;
                    },
                    other: () => 0
                },
                paymentRemarks: {
                    yes: (model) => {
                        let score = 0;
                        model.acceptsPaymentRemarks && (score += 100);
                        return score;
                    },
                    no: (model) => {
                        let score = 0;
                        (!model.acceptsPaymentRemarks) && (score += 25);
                        return score;
                    },
                }
            }
        };
    },
    computed: {
        cards() {
            let cards = Object.values(this.$store.state.cards);
            cards = cards.filter(card => {
                const model = new this.$models.CreditCard(card);
                return ! model.isCorporateCard;
            });
            cards.map(card => {
                return card.score = 
                {
                    benefits: 0,
                    usage: 0,
                    avoidFee: 0,
                    priority: 0,
                    mobilePayments: 0,
                    paymentRemarks: 0,
                    income: 0,
                    age: 0,
                    manualAdjustment: 0,
                    overall: 0,
                };
            });
            return cards;
        },
        hasPaymentRemarks() {
            return this.questionsData.paymentRemarks.activeValue === 'yes';
        },
    },
    methods: {
        setResults() {
            this.cards.map(card => {
                const model = new this.$models.CreditCard(card);

                Object.entries(this.scoreCalculations).forEach(([key, value]) => {
                    card.score[key] += value[this.questionsData[key].activeValue](model);
                });   

                card.score.income = this.incomeScore(model);

                card.score.age = this.ageScore(model);

                card.score.manualAdjustment = this.manualAdjustmentScore(card);
                
                card.score.overall = Object.values(card.score).reduce((a, b) => a + b);

                card.score.overall *= (card.data.application_type !== 'no_partnership' && !card.data.lists_disabled) ? 1.5 : 1;
                
                return card;
            });
        },
        incomeScore(model) {
            const yearlyIncome = this.personalInfoData.income.value * 12;
            let score = 0;
            model.minimumYearlyIncome <= yearlyIncome && (score += 100);
            return score;

        },
        ageScore(model) {
            const age = new Date().getFullYear() - this.personalInfoData.birthYear.value;
            let score = 0;
            model.minAge <= age && (score += 100);
            return score;
        },
        resetScore() {
            this.cards.map(card => {
                Object.keys(card.score).forEach(key => {
                    card.score[key] = 0;
                });
                return card;
            });
        },
        filteredList(){
            return orderBy(this.cards, 'score.overall', 'desc').slice(0, 3);
        },
        getPaymentRemarksLenders() {
            return this.slice.items
                .map(item => item.payment_remark_lender_or_card)
                .filter(item => item && item.type === 'lender');
        },
        getPaymentRemarksCards() {
            return this.slice.items
                .map(item => item.payment_remark_lender_or_card)
                .filter(item => item && item.type === 'card');
        },
        manualAdjustmentScore(card) {
            const scoreLogic = {
                'small_decrease': -5,
                'large_decrease': -20,
                'remove_from_result': -150,
            };
            return scoreLogic[card.data.guide_scoring_adjustment] || 0;
        },
    }
};