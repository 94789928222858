<template>
    <Section width="lg" class="highlighted-card-section">
        <div
            class="container"
            :class="slice.primary.layout"
        >
            <div class="container-inner">
                <div>
                    <nuxt-link :to="$prismic.linkResolver(card)">
                        <prismic-image
                            :img="card.data.image"
                            w="250"
                            h="156"
                            :lazy="false"
                        />
                    </nuxt-link>

                    <card-rating 
                        :rating="card.data.core_api.ratings.average_score" 
                        :card="card"
                    />

                    <ButtonTarget
                        :link="card"
                        background="orange"
                        size="md"
                    />
                </div>
            </div>

            <div class="container-inner">
                <h2 class="card-title">{{ title }}</h2>

                <div
                    v-if="slice.primary.layout === 'usp-boxes'"
                    class="card-boxes"
                >
                    <div class="card-boxes__usp">
                        <span>{{ slice.primary.usp_box_1_big_text }}</span>
                        <div>{{ slice.primary.usp_box_1_text_in_box }}</div>
                    </div>

                    <div class="card-boxes__usp">
                        <span>{{ slice.primary.usp_box_2_big_text }}</span>
                        <div>{{ slice.primary.usp_box_2_text_in_box }}</div>
                    </div>
                </div>

                <div
                    class="card-content rich-text"
                    v-html="$prismic.asHtml(slice.primary.content)"
                />
                <span 
                    v-if="model.complianceExample && $isNorway()" 
                    class="text-compliance"
                >
                    {{ model.complianceExample }}
                </span>
            </div>
        </div>
    </Section>
</template>

<script>
import CardRating from '@/components/features/CardRating.vue';
export default {
    components: { CardRating },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.slice.primary.card.id];
        },
        title() {
            return this.slice.primary.h2 ? this.slice.primary.h2 : this.card.data.title;
        },
        model() {
            return new this.$models.CreditCard(this.card, this.$store);
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        background: $graybg;

        .container {
            background: $white;
            @include grid(1,0);
            @include border;
            @include border-radius(25px);
            box-shadow: $bxs;

            @include device(pad) {
                @include flex(start,stretch);
            }

            &-inner {
                @include spacing(padding,8);

                &:first-child {
                    flex: 1;
                    @include flex;

                    @include device(pad) {
                        @include flex(start,start);
                        display: block;
                        max-width: 330px;
                    }

                    img {
                        margin-top: -70px;
                        height: auto;
                    }

                    .card-rating {
                        width: 100%;
                        margin: 10px 0;
                    }

                    .btn { width: 100%; }
                }
                &:last-child {
                    flex: 1;
                    @include grid(1, 30px);

                    .card-title {
                        @include device(mobile) {
                            text-align: center;
                        }
                    }
                }
            }
        }

        // Layouts
        .container.usp-boxes {
            .card-content::v-deep {
                p { @include font-size(18px,8px); }
            }

            .card-boxes {
                @include grid(1, 20px);
                @include device(desktop) {
                    @include grid(2, 20px);
                }

                &__usp {
                    @include grid(1,10px);
                    text-align: center;

                    span {
                        font-family: $fontHeader;
                        @include font-size(32px);
                    }
                    div {
                        background: $grayblue;
                        color: $white;
                        @include font-size(16px);
                        @include spacing(padding,2);
                    }
                }
            }
        }
        .container.content {
            .card-content::v-deep {
                p { @include font-size(18px,10px); }
            }
        }
    }

    .wrapper-post {
        .highlighted-card-section { background: $white; }
    }
</style>
