<template>
    <div class="latest-posts">
        <h2 class="latest-posts-title">
            {{ title }}
        </h2>
        <nuxt-link 
            v-for="(authorArticle, index) in authorArticles.slice(0, limit)" 
            :key="index"
            :to="$prismic.linkResolver(authorArticle)"
            class="posts-wrapper" 
        >
            <div class="posts-preamble-wrapper">
                <span class="posts-title">{{ authorArticle.data.post_title }}</span>
                <p class="rich-text posts-preamble">{{ latestPostPreamble(authorArticle) }}</p>
                <span class="posts-date">
                    {{ 
                        $translate('author_box_published', 'Publicerad: {timestamp}')
                            .replace('{timestamp}', $format.date(authorArticle.first_publication_date)) 
                    }}
                </span>
            </div>
        </nuxt-link>
        <div class="load-posts">
            <Button
                v-if="limit <= authorArticles.length"
                :text="$translate('show_more', 'Visa mer')"
                icon="fas fa-chevron-down"
                background="gray"
                size="sm"
                @click="updateLimit(limit + 15)"
            />
            <Button
                v-if="limit > 5"
                :text="$translate('show_less', 'Visa mindre')"
                icon="fas fa-chevron-up"
                background="gray"
                size="sm"
                @click="limit = 5"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        authorArticles: {
            type: Array,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            limit: 5,
        };
    },
    methods: {
        latestPostPreamble(post) {
            return post.data.body[0]?.primary?.content;
        },
        updateLimit(value) {
            this.limit = value;
        }
    }
};
</script>
<style lang="scss" scoped>
    .latest-posts {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        
        .latest-posts-title {
            margin-bottom: 20px;
        }
        .posts-wrapper {
            text-decoration: none;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            &:hover {
                .posts-title {
                    text-decoration: none !important;
                }
            }
            .posts-title {
                color: $green;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.75rem;
                text-decoration: underline;
            }
            .posts-preamble {
                margin: 1rem 0 0.5rem 0;
                color: #000;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                @include device(mobile) {
                    -webkit-line-clamp: 4;
                }
            }
            .posts-date {
                display: inline-block;
                color: #333;
                opacity: 0.5;
                margin-top: 0.5rem;
            }
        }
        .load-posts {
            display: flex;
            justify-content: center;
            column-gap: 1rem;
            button {
                margin-top: 1.5rem;
            }
        }
    }
</style>