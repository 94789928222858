<template>
    <Section width="md">
        <div class="container">
            <h2>{{ slice.primary.h2 }}</h2>
            <div 
                v-if="$validateText(slice.primary.text)" 
                class="rich-text" 
                v-html="$prismic.asHtml(slice.primary.text)" 
            />
            <div class="card-container">
                <div 
                    v-for="(author, index) in authors" 
                    :key="index"
                    class="card" 
                >
                    <div class="card-header">
                        <img 
                            :src="author.data.image.url" 
                            :alt="author.data.alt"
                            width="70"
                            height="70" 
                            loading="lazy"   
                        >
                        <div class="card-header-info">
                            <nuxt-link :to="$prismic.linkResolver(author)" class="card-header-name">
                                {{ author.data.name }}
                            </nuxt-link>
                            <p class="card-header-role">
                                {{ author.data.role }}
                            </p>
                        </div>
                    </div>
                    <a
                        class="email"
                        :href="`mailto:${author.data.email}`"    
                    >
                        <i class="fa-solid fa-envelope" />
                        {{ author.data.email }}
                    </a>
                    <p class="card-body">
                        {{ author.data.bio }}
                    </p>
                </div>
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        authors() {
            return this.$store.state.authors;
        }
    }
};
</script>
<style lang='scss' scoped>
    .container {
        .card-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @include device(pad) {
                grid-template-columns: repeat(2, 1fr);
            }
            gap: 2rem;
            margin-top: 2rem;
                .card {
                    gap: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    padding: 2rem;
                    box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, .125);
                    .card-header {
                        display: flex;
                        gap: 0.3rem;
                        .card-header-info {
                            display: flex;
                            flex-direction: column;
                            row-gap: 0.375rem;
                            justify-content: center;
                            .card-header-name {
                                font-weight: bold;
                                &:hover {
                                    color: $green;
                                    text-decoration: underline;
                                }   
                            }
                            .card-header-role {
                                font-weight: lighter;
                                color: #8c98a4; 
                                font-size: .875em;
                            }
                        }
                    }
                    .email {
                        &:hover {
                            color: $green;
                        }
                        i {
                            color: $green;
                            margin-right: 5px;
                        }
                    }
                    .card-body {
                        margin-top: 0.5rem;
                        border-top: .0625rem solid rgba(33, 50, 91, .1);
                        padding-top: 1rem;
                    }
                }
        }
    }
</style>