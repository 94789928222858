<template>
    <div class="form-container__input">
        <span>
            <label :for="uniqueId">
                {{ label }}
            </label>
            <tooltip v-if="tooltipText" :tooltip-text="tooltipText" />
        </span>
        <input
            v-if="!isTextarea"
            :id="uniqueId"
            v-model="input"
            :type="type"
            :name="formKey"
            :placeholder="placeholder"
            @keypress="onlyNumbers($event)"
        >
        <textarea
            v-else
            :id="uniqueId"
            v-model="input"
            :name="formKey"
            :placeholder="placeholder"
            :rows="rows"    
            @keypress="onlyNumbers($event)"
        />
        <div
            v-if="error"
            class="form-container__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash';
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        formKey: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number, null],
            required: false,
            default: null
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: ''
        },
        rows: {
            type: Number,
            required: false,
            default: 6
        },
        isTextarea: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        allowOnlyNumbers: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            input: this.value
        };
    },
    computed: {
        uniqueId() {
            return uniqueId(this.formKey);
        }
    },
    watch: {
        input() {
            this.$emit('set', this.formKey, this.input);
        }
    },
    mounted() {
        this.$emit('set', this.formKey, this.input);
    },
    methods: {
        onlyNumbers($event) {
            if (!this.allowOnlyNumbers) {
                return;
            }
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        }
    }
};
</script>