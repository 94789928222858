// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-115c7568]{font-family:\"Open Sans\"}.font-header[data-v-115c7568]{font-family:\"Hero\"}section[data-v-115c7568] .info-boxes{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(1,1fr)}@media screen and (min-width:600px){section[data-v-115c7568] .info-boxes{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(2,1fr)}}@media screen and (min-width:pad){section[data-v-115c7568] .info-boxes{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(2,1fr)}}section[data-v-115c7568] .info-boxes__box{border:0;border:1px solid rgba(241,214,245,.7);box-shadow:0 10px 15px rgba(169,177,200,.25);border-radius:25px;background:#fff;box-sizing:border-box}@media screen and (max-width:600px){section[data-v-115c7568] .info-boxes__box{padding:32px}}@media screen and (min-width:600px){section[data-v-115c7568] .info-boxes__box{padding:32px}}@media screen and (min-width:900px){section[data-v-115c7568] .info-boxes__box{padding:40px}}section[data-v-115c7568] .info-boxes__box .box-header{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;font-size:22px;line-height:28px;width:100%}@media screen and (max-width:600px){section[data-v-115c7568] .info-boxes__box .box-header{font-size:18px;line-height:24px}}@media screen and (min-width:mobile){section[data-v-115c7568] .info-boxes__box .box-header{font-size:18px;line-height:24px}}section[data-v-115c7568] .info-boxes__box .box-header img{width:40px;height:40px}section[data-v-115c7568] .info-boxes__box .box-header span{margin:10px;flex:1}@media screen and (max-width:360px){section[data-v-115c7568] .info-boxes__box .box-header span{flex:none;width:100%;margin-top:20px;margin-left:0}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
