// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-9d3845da]{font-family:\"Open Sans\"}.font-header[data-v-9d3845da]{font-family:\"Hero\"}.grid-inner[data-v-9d3845da]{display:grid;grid-gap:0;gap:0;grid-template-columns:repeat(1,1fr)}@media screen and (min-width:600px){.grid-inner[data-v-9d3845da]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}}@media screen and (min-width:pad){.grid-inner[data-v-9d3845da]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}}.grid-inner .container[data-v-9d3845da]:first-child{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}.grid-inner .container:first-child .container-inner[data-v-9d3845da]{display:grid;grid-gap:10px;gap:10px;grid-template-columns:repeat(1,1fr)}.grid-inner .container:first-child .container-inner .btn[data-v-9d3845da]{max-width:220px}.grid-inner .container[data-v-9d3845da]:last-child{box-sizing:border-box;flex:1;display:grid;grid-gap:10px;gap:10px;grid-template-columns:repeat(1,1fr)}@media screen and (max-width:600px){.grid-inner .container[data-v-9d3845da]:last-child{padding:24px}}@media screen and (min-width:600px){.grid-inner .container[data-v-9d3845da]:last-child{padding:24px}}@media screen and (min-width:900px){.grid-inner .container[data-v-9d3845da]:last-child{padding:30px}}@media screen and (max-width:600px){.grid-inner .container[data-v-9d3845da]:last-child{text-align:center}}@media screen and (min-width:mobile){.grid-inner .container[data-v-9d3845da]:last-child{text-align:center}}.grid-inner .container:last-child .font-header[data-v-9d3845da]{font-size:20px;line-height:26px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
