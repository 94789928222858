<template>
    <Section width="lg">
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />
        <div class="grid">
            <div class="container">
                <custom-item 
                    :title="slice.primary.highlighted_title"
                    :link="slice.primary.highlighted_link"
                    :image="slice.primary.highlighted_image"
                    :usp-text="slice.primary.highlighted_usp_text"
                    type="highlighted"
                />
            </div>
            <div class="container">
                <custom-item 
                    :title="slice.primary.item_1_title"
                    :link="slice.primary.item_1_link"
                    :image="slice.primary.item_1_image"
                    :usp-text="slice.primary.item_1_usp_text"
                />
                <custom-item 
                    :title="slice.primary.item_2_title"
                    :link="slice.primary.item_2_link"
                    :image="slice.primary.item_2_image"
                    :usp-text="slice.primary.item_2_usp_text"
                />
                <custom-item 
                    :title="slice.primary.item_3_title"
                    :link="slice.primary.item_3_link"
                    :image="slice.primary.item_3_image"
                    :usp-text="slice.primary.item_3_usp_text"
                />
            </div>
        </div>
    </Section>
</template>

<script>
import CustomItem from './SelectedCustomItemsItem';
export default {
    components: {CustomItem},
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        .grid {
            @include grid(1,20px);

            @media screen and (min-width: 700px) {
                @include grid(2,20px);
            }

            .container {

                &:last-child {
                    @include grid(1,20px);
                }

                &-box {
                    box-shadow: $bxs;
                    @include border-radius(25px);
                    background: $white;
                    @include border(all,1px);
                    overflow: hidden;

                    .btn:hover {
                        color: $green;
                        i { margin-left: 15px; }
                    }

                    i {
                        color: $green;
                        transition: .3s ease-in-out;
                        margin-left: 10px;
                    }
                }

                .font-header{
                    word-wrap: anywhere;
                }

                &-box.default {
                    @include grid(1,0);

                    @media screen and (min-width: 500px) {
                        @include grid(2,0);
                    }

                    .font-header {
                        @include font-size(20px,8px);
                    }
                }

                &-box.highlighted {
                    height: 100%;
                    @include grid(1,0);

                    .font-header {
                        @include font-size(20px,8px);
                        
                        @media screen and (min-width: 500px) {
                            @include font-size(32px,8px);
                        }
                    }
                }
            }
        }
    }
}
</style>