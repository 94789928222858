<template>
    <Section
        :class="slice.slice_label"
        :width="width"
    >
        <div class="grid">
            <component
                :is="iterator.layout"
                v-for="(iterator, index) in slice.items"
                :key="index"
                :data="iterator"
                :class="iterator.list_icon"
                :align="iterator.text_alignment"
            />
        </div>
    </Section>
</template>

<script>
import Normal from './RichTextContent.vue';
import ImageBox from './RichTextImageBox.vue';
import VideoPlayer from './RichTextVideoPlayer.vue';
import Quote from './RichTextQuote.vue';
export default {
    components: {
        Normal, ImageBox, VideoPlayer, Quote
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    computed: {
        width() {
            if (this.slice.primary.slice_width) {
                return this.slice.primary.slice_width;
            }
            
            return 'md';
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .grid {
            @include grid(1, 30px);

            // alignments
            .left { text-align: left; }
            .center { text-align: center; }
            .right { text-align: right; }
        }
    }
</style>
