// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-2a78ada8]{font-family:\"Open Sans\"}.font-header[data-v-2a78ada8]{font-family:\"Hero\"}section[data-v-2a78ada8] .grid{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:stretch;grid-gap:20px;gap:20px}section[data-v-2a78ada8] .grid-box{border:0;border:1px solid rgba(241,214,245,.7);border-radius:25px;box-shadow:0 10px 15px rgba(169,177,200,.25);box-sizing:border-box}@media screen and (max-width:600px){section[data-v-2a78ada8] .grid-box{padding:24px}}@media screen and (min-width:600px){section[data-v-2a78ada8] .grid-box{padding:24px}}@media screen and (min-width:900px){section[data-v-2a78ada8] .grid-box{padding:30px}}@media screen and (min-width:600px){section[data-v-2a78ada8] .grid-box{flex:1;min-width:calc(50% - 20px)}}@media screen and (min-width:pad){section[data-v-2a78ada8] .grid-box{flex:1;min-width:calc(50% - 20px)}}section[data-v-2a78ada8] .grid-box h2:first-child,section[data-v-2a78ada8] .grid-box h3:first-child,section[data-v-2a78ada8] .grid-box h4:first-child,section[data-v-2a78ada8] .grid-box h5:first-child p:first-child{margin-top:0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
