<template>
    <div class="pros-cons-section">
        <div v-if="$validateText(title)" class="pros-cons-section-header">
            <img
                :src="image"
                width="30"
                height="30"
                loading="lazy"
            >
            <div
                class="pros-cons-title"
                v-html="$prismic.asHtml(title)"
            />
        </div>
        <div
            v-if="$validateText(teaser)"
            class="rich-text pros-cons-teaser"
            v-html="$prismic.asHtml(teaser)"
        />
        <ul v-if="items.length" class="pros-cons-list">
            <li
                v-for="(item, index) in items"
                :key="index"
            >
                <i 
                    class="fa-regular" 
                    :class="image === '/pros-image.png' ? 'fa-check fa-check1' : 'fa-xmark fa-clear'"
                />
                <span>{{ item.text }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true
        },
        title: {
            type: Array,
            required: false,
            default: null
        },
        teaser: {
            type: Array,
            required: false,
            default: null
        },
        items: {
            type: Array,
            required: false,
            default: null 
        }
        
    }
};
</script>