// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-053e10ac]{font-family:\"Open Sans\"}.font-header[data-v-053e10ac]{font-family:\"Hero\"}.rich-text__quote[data-v-053e10ac]{position:relative}.rich-text__quote .content[data-v-053e10ac]{box-sizing:border-box;text-align:center;font-size:24px;line-height:34px;font-style:italic}@media screen and (max-width:600px){.rich-text__quote .content[data-v-053e10ac]{padding:24px}}@media screen and (min-width:600px){.rich-text__quote .content[data-v-053e10ac]{padding:24px}}@media screen and (min-width:900px){.rich-text__quote .content[data-v-053e10ac]{padding:30px}}.rich-text__quote .container[data-v-053e10ac]{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}.rich-text__quote .container:first-child img[data-v-053e10ac]{margin-right:-10px}.rich-text__quote .container:last-child img[data-v-053e10ac]{margin-left:-10px}.rich-text__quote .container img[data-v-053e10ac]{width:50px;height:50px;position:relative}.rich-text__quote .container .line[data-v-053e10ac]{flex:1;height:1px;background:#01685e}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
