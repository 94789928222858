<template>
    <div class="container-inner">
        <span class="font-header">Steg 2</span>

        <form class="form-container">
            <select-input
                label="Anställningsstatus *"
                name="employmentStatus"
                form-key="employmentStatus"
                :options="[
                    {
                        name: 'Tillsvidareanställd',
                        value: 1
                    },
                    {
                        name: 'Student',
                        value: 2,
                    },
                    {
                        name: 'Pensionär',
                        value: 3
                    },
                    {
                        name: 'Sjukpensionär',
                        value: 4
                    },
                    {
                        name: 'Arbetslös',
                        value: 5
                    },
                    {
                        name: 'Vikarie',
                        value: 6
                    },
                    {
                        name: 'Egenföretagare',
                        value: 7
                    }
                ]"
                @set="setInput"
            />

            <text-input
                label="Arbetsgivare *"
                name="employer"
                form-key="employer"
                @set="setInput"
            />

            <text-input
                label="Månadsinkomst *"
                name="monthlyIncome"
                form-key="monthlyIncome"
                :allow-only-numbers="true"
                @set="setInput"
            />

            <select-input
                label="Boendeform *"
                name="formOfHousing"
                form-key="formOfHousing"
                :options="[
                    {
                        name: 'Villa',
                        value: 1
                    },
                    {
                        name: 'Bostadsrätt',
                        value: 2
                    },
                    {
                        name: 'Hyresrätt',
                        value: 3
                    }
                ]"
                @set="setInput"
            />

            <Button
                background="green"
                text="Nästa steg"
                size="md"
                :class="{disabled: !enableSubmit}"
                @click="$emit('next', item)"
            />
        </form>
    </div>
</template>

<script>
import logic from './form-logic.js';
export default {
    mixins: [logic],
    data() {
        return {
            item: {
                employmentStatus: undefined,
                employer: undefined,
                monthlyIncome: undefined,
                formOfHousing: undefined
            }
        };
    },
    computed: {
        enableSubmit() {
            return (
                this.item.employmentStatus &&
                this.item.employer &&
                this.item.monthlyIncome &&
                this.item.formOfHousing
            );
        }
    }
};
</script>
