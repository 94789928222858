<template>
    <div v-if="$validateText(pros)" class="pros-container">
        <ul>
            <li v-for="(pro, proIndex) in pros" :key="proIndex">
                <i class="fa-solid fa-check-circle" />
                <span>{{ $prismic.asText([pro]) }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pros: {
            type: Array,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
    .pros-container {
        @include border-radius(15px);
        @include border(all,1px,$purplebg);
        box-shadow: $bxs;
        @include spacing(padding, 5);
        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            li {
                display: flex;
                align-items: baseline;
                @include font-size(16px, 8px);
                gap: 10px;
                i {color: #29a329;}
            }
        }
    }
</style>