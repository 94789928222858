<template>
    <Section width="md">
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <faq-grid :faq="slice.items" />
    </Section>
</template>

<script>
import FaqGrid from '@/components/FaqGrid.vue';
export default {
    components: { 
        FaqGrid,
    },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    }
};
</script>