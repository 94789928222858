<template>
    <div
        v-animate="{ threshold: .3, animation: 'up-slide' }"
        class="question-container"
    >
        <span class="question-header">{{ header }}</span>
        <div class="question-options">
            <slot />
        </div>
        <div 
            v-if="errorText && showErrors && ! activeValue"
            class="question-error"
        >
            <span>{{ errorText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true
        },
        activeValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        errorText: {
            type: String,
            required: false,
            default: null
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .question-container {
        padding: 32px;
        @include border(all, 2px, $green);
        @include border-radius(5px);
        @include flex();
        flex-direction: column;
        gap: 20px;
        box-shadow: $bxs;
    }
    .question-header {
        @include font-size(20px);
        word-break: break-word;

        @include device(pad) {
            @include font-size(24px);
        }
        font-weight: bold;
    }
    .question-options {
        width: 100%;
    }
    .question-error {
        width: 100%;
        span {
            color: darkred;
            @include font-size(14px);
            background: rgba(179, 0, 0, .15);
            padding: 15px;
            border: 1px solid darkred;
            border-radius: 2px;
            @include flex();
        }
    }
</style>