<template>
    <Section
        :class="slice.slice_label"
        width="lg"
    >
        <section-header
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <div class="info-boxes">
            <div
                v-for="(box, index) in slice.items"
                :key="index"
                class="info-boxes__box"
            >
                <div class="box-header">
                    <prismic-image
                        v-if="!box.icon.url"
                        :img="placeholder"
                        :alt="box.header"
                        w="80"
                        h="80"
                    />

                    <prismic-image
                        v-else
                        :img="box.icon"
                        w="80"
                        h="80"
                    />

                    <span class="font-header">{{ box.header }}</span>
                </div>

                <div
                    class="box-content rich-text"
                    v-html="$prismic.asHtml(box.content)"
                />
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        placeholder() {
            let image = {
                dimensions: {
                    width: 1920,
                    height: 960
                },
                copyright: null,
                url: 'https://images.prismic.io/kortio/e865e6ed-08cf-4ec5-a84e-4134ca9589a1_target.png?auto=compress,format'
            };
            return image;
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .info-boxes {
            @include grid(1,20px);

            @include device(pad) {
                @include grid(2,20px);
            }

            &__box {
                @include border(all,1px);
                box-shadow: $bxs;
                @include border-radius(25px);
                background: $white;
                @include spacing(padding,8);

                .box-header {
                    @include flex(start);
                    @include font-size(22px);
                    width: 100%;

                    @include device(mobile) {
                        @include font-size(18px);
                    }

                    img {
                        @include size(40px);
                    }

                    span {
                        margin: 10px;
                        flex: 1;

                        @media screen and (max-width: 360px) {
                            flex: none;
                            width: 100%;
                            margin-top: 20px;
                            margin-left: 0;
                        }
                    }
                }

                .box-content {}
            }
        }
    }
</style>
