<template>
    <div class="rich-text__video">
        <div
            v-if="!show"
            class="video-placeholder"
            @click="show = true"
        >
            <i class="fa-brands fa-youtube" />
        </div>

        <div
            v-else
            class="video"
            :class="align"
            v-html="$prismic.asHtml(data.content)"
        />
    </div>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            required: false,
            default: 'left'
        },
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>

<style lang="scss" scoped>
    .rich-text__video {

        .video-placeholder {
            background: $green;
            @include border-radius(25px);
            @include flex;
            cursor: pointer;
            color: $white;
            box-shadow: $bxs;
            font-size: 72px;
            @include size(100%, 200px);
            @include device(pad) {
                height: 400px;
            }
        }

        .video::v-deep {
            iframe {
                @include size(100%, 200px);
                @include device(pad) {
                    height: 400px;
                }
            }
        }
    }
</style>
