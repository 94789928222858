// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-37c08d6f]{font-family:\"Open Sans\"}.font-header[data-v-37c08d6f]{font-family:\"Hero\"}section[data-v-37c08d6f]{padding-top:80px!important;padding-bottom:40px!important}section[data-v-37c08d6f] .section-inner{position:relative}section[data-v-37c08d6f] .section-inner:after,section[data-v-37c08d6f] .section-inner:before{position:absolute;content:\"\";width:120px;height:120px}section[data-v-37c08d6f] .section-inner:after{border:0;box-sizing:border-box;border-top:2px solid #01685e;top:-20px;left:0}section[data-v-37c08d6f] .section-inner:before{border:0;box-sizing:border-box;border-bottom:2px solid #01685e;bottom:-20px;right:0}section[data-v-37c08d6f] p{font-size:20px;line-height:30px;font-weight:500}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
