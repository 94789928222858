var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-inner"},[_c('span',{staticClass:"font-header"},[_vm._v("Steg 3")]),_vm._v(" "),_c('form',{staticClass:"form-container"},[_c('select-input',{attrs:{"label":"Är du en person i politiskt utsatt ställning?","name":"is pep","form-key":"isPep","options":[
                {
                    name: 'Ja',
                    value: true
                },
                {
                    name: 'Nej',
                    value: false
                }
            ]},on:{"set":_vm.setInput}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('boolean-input',{attrs:{"label":"Jag har läst ovan och accepterar villkoren","name":"accepts","form-key":"accepts"},on:{"set":_vm.setInput}}),_vm._v(" "),_c('Button',{class:{disabled: !_vm.enableSubmit},attrs:{"background":"green","text":"Ansök","size":"md"},on:{"click":function($event){return _vm.submit()}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary"},[_c('span',[_vm._v("Vad händer nu?")]),_vm._v(" "),_c('span',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.")])])}]

export { render, staticRenderFns }