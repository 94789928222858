<template>
    <div class="form-container__input">
        <div
            class="input-toggle"
            @click="input =! input"
        >
            <div
                :class="{active: input}"
                class="toggle"
            >
                <i
                    v-if="input"
                    class="fa-regular fa-check fa-check"
                />
            </div>
            <span>{{ label }}</span>
        </div>

        <div
            class="input-text"
            v-html="inputText"
        />

        <div
            v-if="error"
            class="form-container__error"
        >
            {{ errorText }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        formKey: {
            type: String,
            required: true,
            default: String
        },
        label: {
            type: String,
            required: true,
            default: String
        },
        name: {
            type: String,
            required: true,
            default: String
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: String
        },
        inputText: {
            type: String,
            required: false,
            default: String
        }
    },
    data() {
        return {
            input: false
        };
    },
    watch: {
        input() {
            this.$emit('set', this.formKey, this.input);
        }
    }
};
</script>

<style lang="scss" scoped>
    .input-text::v-deep {
        @include font-size(14px,6px);

        a {
            color: $green;
            font-weight: bold;
            &:hover { text-decoration: underline; }
        }
    }
</style>
