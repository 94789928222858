<template>
    <li class="list-item">
        <nuxt-link :to="$prismic.linkResolver(blog)">
            <prismic-image
                :img="blog.data.post_image"
                w="326"
                h="217"
                class="list-item__image"
            />

            <div class="list-item__content">
                <span
                    v-if="blog.data.post_category.id"
                    class="content-category font-header"
                >
                    {{ postCategory }}
                </span>

                <span class="content-title font-header">{{ blog.data.post_title }}</span>

                <p class="content-content">{{ trimmedContent(preamble) }}</p>

                <div class="list-item__footer">
                    <read-time :data="blog" />

                    <timestamp :string="blog.last_publication_date" />
                </div>
            </div>
        </nuxt-link>
    </li>
</template>

<script>
import ReadTime from '@/components/features/ReadTime.vue';
import Timestamp from '@/components/features/Timestamp.vue';
export default {
    components: { ReadTime, Timestamp },
    props: {
        blog: {
            type: Object,
            required: true,
        }
    },
    computed: {
        postCategory() {
            return this.$store.state.postCategories[this.blog.data.post_category.id].data.category;
        },
        preamble() {
            let ingress = this.blog.data.body.filter(item => item.slice_type === 'post_ingress');
            let richText = this.blog.data.body.filter(item => item.slice_type === 'rich_text');

            if (ingress.length) {
                return ingress[0].primary.content;
            }
            return this.$prismic.asText(richText[0].items[0].content);
        }
    },
    methods: {
        trimmedContent(text) {
            if (text.length > 250) {
                return this.$truncateString(text);
            }
            return text;
        }
    }
};
</script>

<style lang="scss" scoped>
    .list-item {
        a {
            @include grid(1,0);
            text-decoration: none;
            color: $font;
            &:hover { color: $green; }

            @media screen and (min-width: 800px) {
                @include flex(start);
            }
        }

        &__image {
            @include border-radius(10px);
            @media screen and (max-width: 800px) {
                width: 100%;
                object-fit: cover;
                margin-bottom: -70px;
            }
        }

        &__content {
            flex: 1;
            @include grid(1,15px);
            @media screen and (max-width: 800px) {
                @include grid(1,10px);
                padding-top: 30px;
            }

            @media screen and (min-width: 800px) {
                padding-left: 30px;
            }

            .content-title {
                @include font-size(24px,8px);
                box-sizing: border-box;
                background: $white;
                max-width: calc(100% - 40px);

                @media screen and (max-width: 800px) {
                    @include border-radius(15px,top-right);
                    padding: 10px 20px 0 0;
                }
            }

            .content-category {
                background: $white;
                font-weight: bold;
                color: $green;
                text-transform: uppercase;
                max-width: calc(100% - 40px);
                @media screen and (max-width: 800px) {
                    @include border-radius(15px,top-right);
                    margin-top: 5px;
                    padding: 20px 20px 0px 0;
                }
            }
        }

        &__footer {
            @include flex(start);
            width: 100%;

            .read-time { margin-right: 20px; }
        }
    }
</style>
