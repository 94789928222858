<template>
    <div class="rich-text__img-box">
        <prismic-image :img="data.image" />

        <div
            class="content"
            :class="align"
            v-html="$prismic.asHtml(data.content)"
        />
    </div>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            required: false,
            default: 'left'
        },
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .rich-text__img-box::v-deep {
        position: relative;

        img {
            position: relative;
            @include size(100%, auto);
            @include border-radius(20px);
            box-shadow: $bxs;
        }

        .content {
            width: 100%;
            margin-top: 10px;
            text-align: center;

            p {
                @include font-size(14px);
                font-style: italic;
            }
        }
    }
</style>
